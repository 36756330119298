import { useState, useEffect } from "react";
import { Link } from 'react-router-dom'


import {
    RiUser2Fill,
    RiPrinterCloudFill,
    RiFileExcel2Fill,
} from "react-icons/ri";
import {
    RiEraserFill,
    RiPencilFill
} from "react-icons/ri";
const initialForm = {
    id: '',
    proyecto: 'gnpcapufe',
    nombre: false,
    rol: '',
    email: '',
    win: '',
    icono: '',
    submenu: false
}

const Modulosform = () => {
    return (
        <>
            <div className="w-full flex flex-col justify-center bg-white items-center">
                <div className="w-full bg-white h-[5vh] text-black">
                </div>
                <div className="flex- flex-col justify-between relative
                 overflow-x-auto shadow-md w-full h-[95vh] bg-white">
                    <div>
                        <span className="text-2xl text-gray-600 pl-5">Modulos</span>
                    </div>
                    <div className="w-full flex flex-cols gap-3 justify-end flex py-2 pr-5">
                        <button className="flex items-center gap-2 h-[35px] bg-orange-400 text-base text-white p-2 rounded-[5px]">
                            <RiUser2Fill />
                        </button>
                     {/*   <button className=" flex items-center gap-2 h-[35px] bg-green-800 text-base text-white p-2 rounded-[5px]">
                            <RiFileExcel2Fill />
                        </button>
                        <button className=" flex items-center gap-2 h-[35px] bg-blue-800 text-base text-white p-2 rounded-[5px]">
                            <RiPrinterCloudFill />
                        </button>*/}
                    </div>
                    <table className="w-full">
                        <thead>
                            <tr className="bg-titTablas text-txtTablas">
                                <th></th>
                                <th class="w-[25]">Modulo</th>                               
                                <th>Orden</th>
                                <th>Menu superior</th>
                                <th>Otra ventana</th>
                                <td>Url</td>
                                <th>Icono</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="flex w-[3vw] m-2">
                                <Link><RiPencilFill /></Link>                                                                
                                </td>
                                <td>Siniestralidad</td>
                                <td className="text-center">1</td>
                                <td>NINGUNO</td>                               
                                <td>no</td>
                                <td></td>                                
                            </tr>
                            <tr>
                                <td className="flex w-[3vw] m-1">
                                <Link><RiPencilFill /></Link>  
                                </td>
                                <td>2023</td>
                                <td className="text-center">1</td>
                                <td>Siniestralidad</td>                               
                                <td>no</td>
                                <td></td>                              
                            </tr>
                            <tr>
                                <td className="flex w-[3vw] m-1">
                                <Link><RiPencilFill /></Link> 
                                </td>
                                <td>Tijuana</td>
                                <td className="text-center">1</td>
                                <td>2023</td>                               
                                <td>no</td>
                                <td>sin/2023/tijuana</td>                              
                            </tr>
                            <tr>
                                <td className="flex w-[3vw] m-1">
                                <Link><RiPencilFill /></Link>  
                                </td>
                                <td>Hermosillo</td>
                                <td className="text-center">2</td>
                                <td>2023</td>                               
                                <td>no</td>
                                <td>sin/2023/hermosillo</td>                              
                            </tr>
                            <tr class>
                                <td className="flex w-[3vw] m">
                                <Link><RiPencilFill /></Link>  
                                </td>
                                <td>Control carretro</td>
                                <td className="text-center">1</td>
                                <td>NINGUNO</td>                               
                                <td>si</td>
                                <td>https://controlcarretero.com</td>                               
                            </tr>
                        
                        </tbody>

                    </table>
                </div>
            </div>


        </>
    )
}
export default Modulosform