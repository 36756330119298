import { React, useState, } from "react";
import { BiSolidDoorOpen } from "react-icons/bi";
import { FaChevronDown, FaChevronUp, FaChartPie } from "react-icons/fa";
import { HiHome, HiMenu } from "react-icons/hi";
import { FaRegUser } from "react-icons/fa";
import { PiNotepadBold } from "react-icons/pi";
import logotipo from "../auth/Login/logofooter.png";
import { PiStudent } from "react-icons/pi";
import { GrDocumentConfig } from "react-icons/gr";
import { Link } from "react-router-dom";
import { auth } from "../data/fire";
import rolesPermisos from "../data/rolesPermisos";
import { FaRoute } from "react-icons/fa6";
import { FaUserCheck } from "react-icons/fa";
import { MdViewModule } from "react-icons/md";
import { MdOutlineRequestQuote } from "react-icons/md";
import { GiProfit } from "react-icons/gi";


const Sidebar2 = ({ autusuario }) => {
  const usrimg = autusuario.photoURL;
  const usuario = autusuario.displayName;
  const email = autusuario.email;
  const uid = autusuario.uid;
  const [expanded, setExpanded] = useState(true);
  const [values, setValues] = useState({});

  /*
siniestralidad 
control facturas
rentabilidad
cotizador
capacitacion 
estaditicas
tramo carretero->control carretero
whatsapp*/
  let nick = 'ninguno'
  switch (autusuario.email) {
    //  usuario
    // Torre de control
    case 'carlos.arreola@fyfasesores.mx':
      nick = 'torrecontrol'
      break;
    case 'georgina.duarte@sinestry.com':
      nick = 'torrecontrol'
      break;
    case 'carina.luna@sinestry.com':
      nick = 'torrecontrol'
      break; 
    case 'myriam.suarez@sinestry.com':
      nick = 'torrecontrol'
      break; 
    case 'innovacion@sinestry.com':
      nick = 'torrecontrol'
      break;
      //Admin 
    case 'paola.perez@serviciosgob.mx':
      nick = 'admin'
      break;
      /*
    case 'carlos.arreola@fyfasesores.mx':
      nick = 'usuariognp'
      break;
      */
    case 'mauricio.valdez@sinestry.com':
      nick = 'admin'
      break;
    case 'manuel.mendoza@sinestry.com':
      nick = 'admin'
      break;
    // DIRECTOR GNP
    case 'director@gnp.sinestryapp.com':
      nick = 'directorgnp'
      break;
    case 'miguelangel.aguilar@gnp.com.mx':
      nick = 'directorgnp'
      break;
    case 'reservas@gnp-capufe.sinestryapp.com':
      nick = 'directorgnp'
      break;
    // COORDINADOR GNP
    case 'rafael.magdaleno@gnp.com.mx':
      nick = 'coordinadorgnp'
      break;
    case 'operador@gnp.sinestryapp.com':
      nick = 'coordinadorgnp'
      break;
    case 'ana.platon@gnp.com.mx':
      nick = 'coordinadorgnp'
      break;
    // GERENTES GNP
    case 'carla.menesses@gnp.com.mx':
      nick = 'gerentegnp'
      break;
    case 'joseluis.barron@gnp.com.mx':
      nick = 'gerentegnp'
      break;
  
    case 'emma.ley@gnp.com.mx':
      nick = 'gerentegnp'
      break;
    case 'guillermo.labarrere@gnp.com.mx':
      nick = 'gerentegnp'
      break;
    case 'maria.dorantes@gnp.com.mx':
      nick = 'gerentegnp'
      break;
    case 'rafael.magdaleno@gnp.com.mx':
      nick = 'gerentegnp'
      break;
    case 'ana.platon@gnp.com.mx':
      nick = 'gerentegnp'
      break;
    case 'paola.perez@sinestry.com':
      nick = 'gerentegnp'
      break;
    case 'mauricio.valdez@serviciosgob.mx':
      nick = 'gerentegnp'
      break;
    case 'gerente@gnp.sinestryapp.com':
      nick = 'gerentegnp'
      break;
    case 'diana.lara@gnp.com.mx':
      nick = 'gerentegnp'
      break;
    case 'emma.ley@gnp.com.mx':
      nick = 'gerentegnp'
      break;
    // DIRECTORES SINESTRY
    case 'director@sinestryapp.com':
      nick = 'directorsinestry'
      break;
    case 'pedro.vergara@sinestry.com':
      nick = 'directorsinestry'
      break;
    case 'pedro.vergara@serviciosgob.mx':
      nick = 'directorsinestry'
      break;
    case 'pedro.vergara@fyfasesores.mx':
      nick = 'directorsinestry'
      break;
    case 'francisco.vergara@sinestry.com':
      nick = 'directorsinestry'
      break;
    case 'francisco.vergara@serviciosgob.mx':
      nick = 'directorsinestry'
      break;
    case 'francisco.vergara@fyfasesores.mx':
      nick = 'directorsinestry'
      break;
    case 'gonzalo.bravo@sinestry.com':
      nick = 'directorsinestry'
      break;
    case 'gonzalo.bravo@serviciosgob.mx':
      nick = 'directorsinestry'
      break;
    case 'gonzalo.bravo@fyfasesores':
      nick = 'directorsinestry'
      break;
    // OPERATIVO SINESTRY
    case 'operador@sinestryapp.com':
      nick = 'operativosinestry'
      break;
    case 'carina.luna@sinestry.com':
      nick = 'operativosinestry'
      break;

    case 'miriam.zuarez@sinestry.com':
      nick = 'operativosinestry'
      break;
    case 'david.sotelo@serviciosgob.mx':
      nick = 'operativosinestry'
      break;
    // GERENTE SINESTRY
    case 'yessenia.luna@sinestry.com':
      nick = 'coordinadorsinestry'
      break;
    case 'yessenia.luna@serviciosgob.mx':
      nick = 'coordinadorsinestry'
      break;
    case 'hector.trujillo@serviciosgob.mx':
      nick = 'coordinadorsinestry'
      break;
    // ninguno
    default:
      nick = 'ninguno'
      break;
  }


  // Función para actualizar los valores de forma dinámica
  const handleChange = (key, value) => {
    setExpanded(true)
    setValues(prevValues => ({
      ...prevValues,
      [key]: value
    }));
  };
  //
  function handleClick() {
    setExpanded((curr) => !curr);
  }
  function handleClose() {
    auth.signOut();
  }
  return (
    <div
      className={`bg-grisSinestry text-white min-h-screen flex flex-col ${expanded ? "w-[27vw]" : "w-[5vw]"}`}
    >
      {/* logotipo */}
      <div className="py-3 px-3 flex flex-row justify-around">
        <div className={`w-full px-5 ${expanded ? "" : "hidden"}`}>
          <img className="w-[80%] h-[90%]" src={logotipo} alt="" />
        </div>
        <div onClick={handleClick}>
          <HiMenu size={23} className="cursor-pointer" />
        </div>
      </div>
      {/* usuario imagen login */}
      <div className="w-[100%] h-auto flex justify-center ">
        <img src={usrimg} alt="" className="w-[30%] rounded-[50%]" />
      </div>
      {/* usuario logueado */}
      <div
        className={`flex flex-col justify-center items-center ${expanded ? "" : "hidden"}`}
      >
        <p className={`bold text-[.9rem] `}>{usuario}</p>
        <p className="text-[.9rem]">{email}</p>
      </div>
      {/* menu  */}
      <div className=" mt-4 flex flex-col gap-4 relative">
        <ul className="w-auto text-[12px] px-2">
          <li
            className={`flex items-center  ${expanded ? "mt-[1vh]" : "mt-[9vh] mb-2"} text-white hover:bg-moradoSinestryClaro pb-1 rounded-md hover:text-white`}
          ><Link className="flex gap-x-2"
            to="/">

              <span className="text-[1.1rem] pl-4 pt-2 block float-left">
                <HiHome />
              </span>
              <span
                className={`${expanded ? "" : "hidden"} text-[.8rem] pt-2 font-semibold flex-1`}
              >
                Inicio
              </span></Link>
          </li>

          {rolesPermisos[nick].menus.map((itemmenu) => (
            <>
              <li
                onClick={(e) => handleChange(itemmenu.clave, !values[itemmenu.clave])}
                // onClick={()=>{setSubmenuOpen(!submenuOpen);}}
                key={itemmenu.id}
                className={`flex items-center justify-between gap-x-9 ${expanded ? "" : " mb-2"} pb-1 text-btntTxtBcoSinApp hover:bg-btninApp rounded-md hover:text-white`}
              >
                <Link to={itemmenu.url} target={itemmenu.win === "" ? "" : itemmenu.win} className="flex gap-x-2">
                  <span className={`text-[1.1rem]  pl-4 pt-2 block float-left`}>
                    {itemmenu.icono}
                  </span>
                  <span
                    className={`${expanded ? "" : "hidden"} text-[.8rem] pt-2 font-semibold flex-1 duration-200`}
                  >
                    {itemmenu.titulo}
                  </span>
                </Link>
                {itemmenu.submenu && expanded && (
                  <span className=" text-[.8rem] px-4 pt-2 block float-left">
                    {values[itemmenu.clave] ? <FaChevronUp /> : <FaChevronDown />}
                  </span>
                )}
              </li>
              {/* nivel 1 */}
              {itemmenu.submenu && values[itemmenu.clave] && expanded && (
                <ul className={`${values[itemmenu.clave] ? '' : 'hidden'}`}>
                  {itemmenu.submenus.map((subitem) => (
                    <>
                      <li
                        //  onClick={()=>{setSubmenuOpen1(!submenuOpen1)}}
                        onClick={(e) => handleChange(subitem.clave, !values[subitem.clave])}
                        key={subitem.id}
                        className={`flex items-center justify-between gap-x-2 ${expanded ? "" : " mb-2"} pb-1  text-white hover:bg-moradoSinestryClaro  rounded-md hover:text-white`}
                      > <Link to={subitem.url} target={subitem.win === "" ? "" : subitem.win} className="flex gap-x-2">
                          <span
                            className={`text-[1.1rem] ${subitem.spaces ? "pl-6" : "pl-2"} pl-4 pt-2 block float-left`}
                          >
                            {subitem.icono}
                          </span>
                          <span
                            className={`${expanded ? "" : "hidden"} text-[.8rem] pt-2 font-semibold flex-1 duration-200`}
                          >
                            {subitem.titulo}
                          </span>
                        </Link>

                        {subitem.submenu && (
                          <span className="text-[.8rem] px-4 pt-2 block float-left">
                            {values[subitem.clave] ? <FaChevronUp /> : <FaChevronDown />}
                          </span>
                        )}
                      </li>
                      {subitem.submenu && values[subitem.clave] && expanded && (
                        <ul className={`${values[subitem.clave] ? '' : 'hidden'}`}>
                          {subitem.submenus.map((subitem2) => (
                            <>
                              <li key={subitem2.id}
                                className={`flex items-center gap-x-2 ${expanded ? "" : " mb-2"} pb-1  text-white hover:bg-moradoSinestryClaro  rounded-md hover:text-white`}
                              >
                                <Link to={subitem2.url} target={subitem2.win === "" ? "" : subitem2.win} className="flex gap-x-2"><span
                                  className={`text-[1.1rem] ${subitem2.spaces ? "pl-8" : "pl-2"} pl-4 pt-2 block float-left`}
                                >
                                  {subitem2.icono}
                                </span>

                                  <span
                                    className={`${expanded ? "" : "hidden"} text-[.8rem] pt-2 font-semibold flex-1 duration-200`}
                                  >
                                    {subitem2.titulo}
                                  </span>
                                </Link>
                              </li>
                            </>
                          ))}
                        </ul>
                      )}
                    </>
                  ))}
                </ul>
              )}
            </>
          ))}

          <li
            className={`flex  items-center gap-x-9  pb-1 text-white hover:bg-moradoSinestryClaro rounded-md hover:text-white`}
          >
            <Link className="flex gap-x-2"
              onClick={handleClose}>

              <span className="text-[1.1rem] pl-4 pt-2 first-line:block float-left">
                <BiSolidDoorOpen />
              </span>
              <span
                className={`${expanded ? "" : "hidden"} pt-2 text-[.8rem] font-semibold flex-1`}
              >
                Salir
              </span></Link>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default Sidebar2;
