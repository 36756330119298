
import { PiNotepadBold } from "react-icons/pi";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaChartPie } from "react-icons/fa";
import { PiStudent } from "react-icons/pi";
import { GrDocumentConfig } from "react-icons/gr";
import { FaRoute } from "react-icons/fa6";
import { FaRegUser } from "react-icons/fa";
import { MdViewModule } from "react-icons/md";
import { FaUserCheck } from "react-icons/fa";
import { MdOutlineRequestQuote } from "react-icons/md";
import { GiProfit } from "react-icons/gi";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";

const rolesPermisos = {
  ninguno: {
    menus: [{
      id: 99,
      spaces: true,
      titulo: "Whatsapp",
      clave: "whatsap",
      url: "https://api.whatsapp.com/send?phone=5215578786789&amp;text=%C2%A1Hola%2C%20bienvenido%20al%20servicio%20de%20SINESTRY!%20%0AA%20trav%C3%A9s%20de%20este%20canal%2C%20podremos%20resolverte%20cualquier%20duda%20que%20tengas%20para%20la%20atenci%C3%B3n%20de%20siniestros.%0AEstamos%20aqu%C3%AD%20para%20ayudarte.%20%20%0A%C2%A1Gracias!",
      win: "_blank",
      icono: <IoLogoWhatsapp />,
      submenu: false,
      submenus: [],
    },]
  },
  admin: {
    menus: [
      { id: 1,
        spaces: false,
        titulo: "Control de obras",
        clave: "controlobras",
        url: "/",
        win: "",
        icono: <PiNotepadBold />,
        submenu: false,
        submenus: [],},
      // Siniestrlidad
      {
        id: 1,
        spaces: false,
        titulo: "Siniestralidad",
        clave: "siniestralidad",
        url: "/",
        win: "",
        icono: <PiNotepadBold />,
        submenu: true,
        submenus: [
          {
            id: 1,
            spaces: true,
            titulo: "2023",
            clave: "sin2023",
            url: "/",
            win: "",
            icono: <PiNotepadBold />,
            submenu: true,
            submenus: [
              {
                id: 1,
                spaces: true,
                titulo: "Tijuana",
                clave: "tijuana",
                url: "/sin/2023/tijuana",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 2,
                spaces: true,
                titulo: "Hermosillo",
                clave: "hermosillo",
                url: "/sin/2023/hermosillo",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 3,
                spaces: true,
                titulo: "Reynosa",
                clave: "reynosa",
                url: "/sin/2023/reynosa",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 4,
                spaces: true,
                titulo: "Saltillo",
                clave: "saltillo",
                url: "/sin/2023/saltillo",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 5,
                spaces: true,
                titulo: "Mazatlán",
                clave: "mazatlan",
                url: "/sin/2023/mazatlan",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Quéretaro",
                clave: "reynosa",
                url: "/sin/2023/queretaro",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 7,
                spaces: true,
                titulo: "Cuernavaca",
                clave: "cuernavaca",
                url: "/sin/2023/cuernavaca",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 2,
                spaces: true,
                titulo: "Puebla",
                clave: "puebla",
                url: "/sin/2023/puebla",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 3,
                spaces: true,
                titulo: "Veracruz",
                clave: "veracruz",
                url: "/sin/2023/veracruz",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 4,
                spaces: true,
                titulo: "Coatzacoalcos",
                clave: "coatzacoalcos",
                url: "/sin/2023/coatzacoalcos",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 5,
                spaces: true,
                titulo: "Estado de México",
                clave: "edomex",
                url: "/sin/2023/edomex",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Villahermosa",
                clave: "villahermosa",
                url: "/sin/2023/villahermosa",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Oaxaca",
                clave: "oaxaca",
                url: "/sin/2023/oaxaca",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
            ],
          },
          {
            id: 1,
            spaces: true,
            titulo: "2024",
            clave: "sin2024",
            url: "/",
            win: "",
            icono: <PiNotepadBold />,
            submenu: true,
            submenus: [
              {
                id: 1,
                spaces: true,
                titulo: "Tijuana",
                clave: "tijuana",
                url: "/sin/2024/tijuana",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 2,
                spaces: true,
                titulo: "Hermosillo",
                clave: "hermosillo",
                url: "/sin/2024/hermosillo",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 3,
                spaces: true,
                titulo: "Reynosa",
                clave: "reynosa",
                url: "/sin/2024/reynosa",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 4,
                spaces: true,
                titulo: "Saltillo",
                clave: "saltillo",
                url: "/sin/2024/saltillo",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 5,
                spaces: true,
                titulo: "Mazatlán",
                clave: "mazatlan",
                url: "/sin/2024/mazatlan",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Quéretaro",
                clave: "reynosa",
                url: "/sin/2024/queretaro",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 7,
                spaces: true,
                titulo: "Cuernavaca",
                clave: "cuernavaca",
                url: "/sin/2024/cuernavaca",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 2,
                spaces: true,
                titulo: "Puebla",
                clave: "puebla",
                url: "/sin/2024/puebla",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 3,
                spaces: true,
                titulo: "Veracruz",
                clave: "  veracruz",
                url: "/sin/2024/veracruz",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 4,
                spaces: true,
                titulo: "Coatzacoalcos",
                clave: "coatzacoalcos",
                url: "/sin/2024/coatzacoalcos",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 5,
                spaces: true,
                titulo: "Estado de México",
                clave: "edomex",
                url: "/sin/2024/edomex",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Villahermosa",
                clave: "villahermosa",
                url: "/sin/2024/villahermosa",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Oaxaca",
                clave: "oaxaca",
                url: "/sin/2024/oaxaca",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
            ],
          },
        ],
      },
      // Control de facturas    
      {
        id: 2,
        spaces: true,
        titulo: "Control de facturas",
        clave: "ctrfacturas",
        url: "/",
        win: "",
        icono: <LiaFileInvoiceDollarSolid />,
        submenu: true,
        submenus: [{
          id: 2,
          spaces: true,
          titulo: "GNP-CAPUFE Control de facturas 2020-2022",
          clave: "gnp2024",
          url: "/ctf/controlfact2022",
          win: "",
          icono: <LiaFileInvoiceDollarSolid />,
          submenu: true,
          submenus: [],
        }, {
          id: 2,
          spaces: true,
          titulo: "GNP-CAPUFE Control de facturas 2022-2024",
          clave: "gnp2224",
          url: "/ctf/controlfact2224",
          win: "",
          icono: <LiaFileInvoiceDollarSolid />,
          submenu: true,
          submenus: [],
        }, {
          id: 2,
          spaces: true,
          titulo: "Control Sin.Mayores a un millón",
          clave: "gnpmillon",
          url: "/ctf/controlsinmill",
          win: "",
          icono: <LiaFileInvoiceDollarSolid />,
          submenu: true,
          submenus: [],
        },],
      },
      // Rentabilidad
      {
        id: 2,
        spaces: true,
        titulo: "Rentabilidad",
        clave: "rentabilidad",
        url: "/",
        win: "",
        icono: <GiProfit />,
        submenu: true,
        submenus: [{
          id: 2,
          spaces: true,
          titulo: "Aforo vehicular y primas cobradas",
          clave: "aforo",
          url: "/ren/aforo",
          win: "",
          icono: <GiProfit />,
          submenu: true,
          submenus: [],
        }, {
          id: 2,
          spaces: true,
          titulo: "Analisis de rentabilidad de montos 2020 al 2024",
          clave: "analisissin",
          url: "/ren/analisissin",
          win: "",
          icono: <GiProfit />,
          submenu: true,
          submenus: [],
        },],
      },
      //Cotizador
      {
        id: 3,
        spaces: false,
        titulo: "Cotizador",
        clave: "cotizador",
        url: "/",
        win: "",
        icono: <MdOutlineRequestQuote />,
        submenu: true,
        submenus: [{
          id: 2,
          spaces: false,
          titulo: "Cotizador",
          clave: "cotizador",
          url: "/cot/cotizador",
          win: "",
          icono: <MdOutlineRequestQuote />,
          submenu: false,
          submenus: [],
        },],
      },
      // Estadisticas
      {
        id: 5,
        spaces: true,
        titulo: "Estadísticas",
        clave: "estadisticas",
        url: "/",
        win: "",
        icono: <FaChartPie />,
        submenu: true,
        submenus: [
          {
            id: 5,
            spaces: true,
            titulo: "Control carretero",
            clave: "estcontrolcarretero",
            url: "/",
            win: "",
            icono: <FaChartPie />,
            submenu: true,
            submenus: [{
              id: 5,
              spaces: true,
              titulo: "Seguimiento contratistas",
              clave: "segcontatistas",
              url: "/est/segcontatistas",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            },],
          },
          {
            id: 5,
            spaces: true,
            titulo: "Control de facturas",
            clave: "estcontfacturas",
            url: "/",
            win: "",
            icono: <FaChartPie />,
            submenu: true,
            submenus: [{
              id: 5,
              spaces: true,
              titulo: "Seguimiento contratistas",
              clave: "segcontatistas",
              url: "/est/segcontatistas",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            }, {
              id: 5,
              spaces: true,
              titulo: "Control de siniestros especialesy servicios",
              clave: "siniestrosespeciales",
              url: "/est/siniestrosespeciales",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            }, {
              id: 5,
              spaces: true,
              titulo: "Seguimiento solicitudes de pago de equipo electronico y obra civil",
              clave: "pagoequipos",
              url: "/est/pagoequipos",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            }, {
              id: 5,
              spaces: true,
              titulo: "Reporte de montos reclmados CAPUFE",
              clave: "reclamadoscapufe",
              url: "/est/reclamadoscapufe",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            },],
          },
          {
            id: 5,
            spaces: true,
            titulo: "Siniestralidad",
            clave: "estsinestralidad",
            url: "/",
            win: "",
            icono: <FaChartPie />,
            submenu: true,
            submenus: [{
              id: 5,
              spaces: true,
              titulo: "Supervisión de sesiones quincenales",
              clave: "sesionesquincenales",
              url: "/est/sesionesquincenales",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            }, {
              id: 5,
              spaces: true,
              titulo: "Torre de control",
              clave: "torrecontrol",
              url: "/est/torrecontrol",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            }, {
              id: 5,
              spaces: true,
              titulo: "Analisis de siniestralidad",
              clave: "analisis",
              url: "/est/analisis",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            },
            {
              id: 5,
              spaces: true,
              titulo: "Siniestralidad reportes para siniestros especiales",
              clave: "siniestrosespeciales",
              url: "/est/siniestrosespeciales",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            }, {
              id: 5,
              spaces: true,
              titulo: "Siniestralidad reportes de montos reclamados por CAPUFE",
              clave: "reclamadoscapufe",
              url: "/est/reclamadoscapufe",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            },],
          },
        ],
      },
      
      // Control caretero
      {
        id: 6,
        spaces: true,
        titulo: "Control carretero",
        clave: "controlcarretero",
        url: "/",
        win: "",
        icono: <FaRoute />,
        submenu: true,
        submenus: [{
          id: 6,
          spaces: true,
          titulo: "Control carretero",
          clave: "crlcarretero",
          url: "https://controlcarretero.com/gnp/login.aspx?ReturnUrl=%2fgnp%2fApplication%2fdashboard.aspx",
          win: "_blank",
          icono: <FaRoute />,
          submenu: false,
          submenus: [],
        },],
      },
      //Configuracion
      {
        id: 10,
        spaces: false,
        titulo: "Configuración",
        clave: "configuracion",
        url: "/",
        win: "",
        icono: <GrDocumentConfig />,
        submenu: true,
        submenus: [{
          id: 5,
          spaces: true,
          titulo: "Usuarios",
          clave: "usuarios",
          url: "/cnf/usuarios",
          win: "",
          icono: <FaRegUser />,
          submenu: false,
          submenus: [],
        }, 
        {
          id: 5,
          spaces: true,
          titulo: "Roles",
          clave: "roles",
          url: "/cnf/roles",
          win: "",
          icono: <MdViewModule />,
          submenu: false,
          submenus: [],
        },{
          id: 5,
          spaces: true,
          titulo: "Modulos",
          clave: "modulos",
          url: "/cnf/modulos",
          win: "",
          icono: <MdViewModule />,
          submenu: false,
          submenus: [],
        }, {
          id: 5,
          spaces: true,
          titulo: "Permisos",
          clave: "permisos",
          url: "/cnf/permisoss",
          win: "",
          icono: <FaUserCheck />,
          submenu: false,
          submenus: [],
        },],
      },
      /* CAPACITACION */
      {
        id: 98,
        spaces: true,
        titulo: "Capacitación",
        clave: "Capacitación",
        url: "/cap/capacitacion",
        win: "",
        icono: <PiStudent />,
        submenu: false,
        submenus: [],
      },
      //Whatsapp
      {
        id: 99,
        spaces: true,
        titulo: "Whatsapp",
        clave: "whatsap",
        url: "https://api.whatsapp.com/send?phone=5215578786789&amp;text=%C2%A1Hola%2C%20bienvenido%20al%20servicio%20de%20SINESTRY!%20%0AA%20trav%C3%A9s%20de%20este%20canal%2C%20podremos%20resolverte%20cualquier%20duda%20que%20tengas%20para%20la%20atenci%C3%B3n%20de%20siniestros.%0AEstamos%20aqu%C3%AD%20para%20ayudarte.%20%20%0A%C2%A1Gracias!",
        win: "_blank",
        icono: <IoLogoWhatsapp />,
        submenu: false,
        submenus: [],
      },
    ],
  },
  usuario: {
    menus: [
       // control de facturas    
      {
        id: 2,
        spaces: true,
        titulo: "Control de facturas",
        clave: "ctrfacturas",
        url: "/",
        win: "",
        icono: <LiaFileInvoiceDollarSolid />,
        submenu: true,
        submenus: [{
          id: 2,
          spaces: true,
          titulo: "Control Sin.Mayores a un millón",
          clave: "gnpmillon",
          url: "/ctf/controlsinmill",
          win: "",
          icono: <LiaFileInvoiceDollarSolid />,
          submenu: true,
          submenus: [],
        },],
      },

      // Estadisticas
      {
        id: 5,
        spaces: true,
        titulo: "Estadísticas",
        clave: "estadisticas",
        url: "/",
        win: "",
        icono: <FaChartPie />,
        submenu: true,
        submenus: [
          
          {
            id: 5,
            spaces: true,
            titulo: "Control de facturas",
            clave: "estcontfacturas",
            url: "/",
            win: "",
            icono: <FaChartPie />,
            submenu: true,
            submenus: [ {
              id: 5,
              spaces: true,
              titulo: "Reporte de montos reclmados CAPUFE",
              clave: "reclamadoscapufe",
              url: "/est/reclamadoscapufe",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            },],
          },
          {
            id: 5,
            spaces: true,
            titulo: "Siniestralidad",
            clave: "estsinestralidad",
            url: "/",
            win: "",
            icono: <FaChartPie />,
            submenu: true,
            submenus: [{
              id: 5,
              spaces: true,
              titulo: "Supervisión de sesiones quincenales",
              clave: "sesionesquincenales",
              url: "/est/sesionesquincenales",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            },
             {
              id: 5,
              spaces: true,
              titulo: "Siniestralidad reportes de montos reclamados por CAPUFE",
              clave: "reclamadoscapufe",
              url: "/est/reclamadoscapufe",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            },],
          },
        ],
      },
      // Control caretero
      /*
      {
        id: 6,
        spaces: true,
        titulo: "Control carretero",
        clave: "controlcarretero",
        url: "/",
        win: "",
        icono: <FaRoute />,
        submenu: true,
        submenus: [{
          id: 6,
          spaces: true,
          titulo: "Control carretero",
          clave: "crlcarretero",
          url: "https://controlcarretero.com/gnp/login.aspx?ReturnUrl=%2fgnp%2fApplication%2fdashboard.aspx",
          win: "_blank",
          icono: <FaRoute />,
          submenu: false,
          submenus: [],
        },],
      },
      */
      //Configuracion
      /*
      {
        id: 10,
        spaces: false,
        titulo: "Configuración",
        clave: "configuracion",
        url: "/",
        win: "",
        icono: <GrDocumentConfig />,
        submenu: true,
        submenus: [{
          id: 5,
          spaces: true,
          titulo: "Usuarios",
          clave: "usuarios",
          url: "/cnf/usuarios",
          win: "",
          icono: <FaRegUser />,
          submenu: false,
          submenus: [],
        }, {
          id: 5,
          spaces: true,
          titulo: "Modulos",
          clave: "modulos",
          url: "/cnf/modulos",
          win: "",
          icono: <MdViewModule />,
          submenu: false,
          submenus: [],
        }, {
          id: 5,
          spaces: true,
          titulo: "Permisos",
          clave: "permisos",
          url: "/cnf/permisoss",
          win: "",
          icono: <FaUserCheck />,
          submenu: false,
          submenus: [],
        },],
      },
 /* CAPACITACION */
 {
  id: 98,
  spaces: true,
  titulo: "Capacitación",
  clave: "Capacitación",
  url: "/cap/capacitacion",
  win: "",
  icono: <PiStudent />,
  submenu: false,
  submenus: [],
},

    ],
  },
  directorsinestry: {
    menus: [
      {
        id: 1,
        spaces: false,
        titulo: "Siniestralidad",
        clave: "siniestralidad",
        url: "/",
        win: "",
        icono: <PiNotepadBold />,
        submenu: true,
        submenus: [
          {
            id: 1,
            spaces: true,
            titulo: "2023",
            clave: "sin2023",
            url: "/",
            win: "",
            icono: <PiNotepadBold />,
            submenu: true,
            submenus: [
              {
                id: 1,
                spaces: true,
                titulo: "Tijuana",
                clave: "tijuana",
                url: "/sin/2023/tijuana",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 2,
                spaces: true,
                titulo: "Hermosillo",
                clave: "hermosillo",
                url: "/sin/2023/hermosillo",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 3,
                spaces: true,
                titulo: "Reynosa",
                clave: "reynosa",
                url: "/sin/2023/reynosa",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 4,
                spaces: true,
                titulo: "Saltillo",
                clave: "saltillo",
                url: "/sin/2023/saltillo",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 5,
                spaces: true,
                titulo: "Mazatlán",
                clave: "mazatlan",
                url: "/sin/2023/mazatlan",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Quéretaro",
                clave: "reynosa",
                url: "/sin/2023/queretaro",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 7,
                spaces: true,
                titulo: "Cuernavaca",
                clave: "cuernavaca",
                url: "/sin/2023/cuernavaca",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 2,
                spaces: true,
                titulo: "Puebla",
                clave: "puebla",
                url: "/sin/2023/puebla",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 3,
                spaces: true,
                titulo: "Veracruz",
                clave: "veracruz",
                url: "/sin/2023/veracruz",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 4,
                spaces: true,
                titulo: "Coatzacoalcos",
                clave: "coatzacoalcos",
                url: "/sin/2023/coatzacoalcos",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 5,
                spaces: true,
                titulo: "Estado de México",
                clave: "edomex",
                url: "/sin/2023/edomex",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Villahermosa",
                clave: "villahermosa",
                url: "/sin/2023/villahermosa",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },             
            ],
          },
          {
            id: 1,
            spaces: true,
            titulo: "2024",
            clave: "sin2024",
            url: "/",
            win: "",
            icono: <PiNotepadBold />,
            submenu: true,
            submenus: [
              {
                id: 1,
                spaces: true,
                titulo: "Tijuana",
                clave: "tijuana",
                url: "/sin/2024/tijuana",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 2,
                spaces: true,
                titulo: "Hermosillo",
                clave: "hermosillo",
                url: "/sin/2024/hermosillo",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 3,
                spaces: true,
                titulo: "Reynosa",
                clave: "reynosa",
                url: "/sin/2024/reynosa",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 4,
                spaces: true,
                titulo: "Saltillo",
                clave: "saltillo",
                url: "/sin/2024/saltillo",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 5,
                spaces: true,
                titulo: "Mazatlán",
                clave: "mazatlan",
                url: "/sin/2024/mazatlan",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Quéretaro",
                clave: "reynosa",
                url: "/sin/2024/queretaro",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 7,
                spaces: true,
                titulo: "Cuernavaca",
                clave: "cuernavaca",
                url: "/sin/2024/cuernavaca",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 2,
                spaces: true,
                titulo: "Puebla",
                clave: "puebla",
                url: "/sin/2024/puebla",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 3,
                spaces: true,
                titulo: "Veracruz",
                clave: "  veracruz",
                url: "/sin/2024/veracruz",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 4,
                spaces: true,
                titulo: "Coatzacoalcos",
                clave: "coatzacoalcos",
                url: "/sin/2024/coatzacoalcos",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 5,
                spaces: true,
                titulo: "Estado de México",
                clave: "edomex",
                url: "/sin/2024/edomex",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Villahermosa",
                clave: "villahermosa",
                url: "/sin/2024/villahermosa",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Oaxaca",
                clave: "oaxaca",
                url: "/sin/2024/oaxaca",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
            ],
          },
        ],
      },

      {
        id: 2,
        spaces: true,
        titulo: "Control de facturas",
        clave: "ctrfacturas",
        url: "/",
        win: "",
        icono: <LiaFileInvoiceDollarSolid />,
        submenu: true,
        submenus: [{
          id: 2,
          spaces: true,
          titulo: "GNP-CAPUFE Control de facturas 2020-2022",
          clave: "gnp2024",
          url: "/ctf/controlfact2024",
          win: "",
          icono: <LiaFileInvoiceDollarSolid />,
          submenu: true,
          submenus: [],
        }, {
          id: 2,
          spaces: true,
          titulo: "GNP-CAPUFE Control de facturas 2022-2024",
          clave: "gnp2224",
          url: "/ctf/controlfact2224",
          win: "",
          icono: <LiaFileInvoiceDollarSolid />,
          submenu: true,
          submenus: [],
        }, {
          id: 2,
          spaces: true,
          titulo: "Control Sin.Mayores a un millón",
          clave: "gnpmillon",
          url: "/ctf/controlsinmill",
          win: "",
          icono: <LiaFileInvoiceDollarSolid />,
          submenu: true,
          submenus: [],
        },],
      },
      {
        id: 2,
        spaces: true,
        titulo: "Rentabilidad",
        clave: "rentabilidad",
        url: "/",
        win: "",
        icono: <GiProfit />,
        submenu: true,
        submenus: [{
          id: 2,
          spaces: true,
          titulo: "Aforo vehicular y primas cobradas",
          clave: "aforo",
          url: "/ren/aforo",
          win: "",
          icono: <GiProfit />,
          submenu: true,
          submenus: [],
        }, {
          id: 2,
          spaces: true,
          titulo: "Analisis de rentabilidad de montos 2020 al 2024",
          clave: "analisissin",
          url: "/ren/analisissin",
          win: "",
          icono: <GiProfit />,
          submenu: true,
          submenus: [],
        },],
      },
      {
        id: 3,
        spaces: false,
        titulo: "Cotizador",
        clave: "cotizador",
        url: "/",
        win: "",
        icono: <MdOutlineRequestQuote />,
        submenu: true,
        submenus: [{
          id: 2,
          spaces: false,
          titulo: "Cotizador",
          clave: "cotizador",
          url: "/cot/cotizador",
          win: "",
          icono: <MdOutlineRequestQuote />,
          submenu: false,
          submenus: [],
        },],
      },

      {
        id: 5,
        spaces: true,
        titulo: "Estadísticas",
        clave: "estadisticas",
        url: "/",
        win: "",
        icono: <FaChartPie />,
        submenu: true,
        submenus: [
          {
            id: 5,
            spaces: true,
            titulo: "Control carretero",
            clave: "estcontrolcarretero",
            url: "/",
            win: "",
            icono: <FaChartPie />,
            submenu: true,
            submenus: [{
              id: 5,
              spaces: true,
              titulo: "Seguimiento contratistas",
              clave: "segcontatistas",
              url: "/est/segcontatistas",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            },],
          },
          {
            id: 5,
            spaces: true,
            titulo: "Control de facturas",
            clave: "estcontfacturas",
            url: "/",
            win: "",
            icono: <FaChartPie />,
            submenu: true,
            submenus: [{
              id: 5,
              spaces: true,
              titulo: "Seguimiento contratistas",
              clave: "segcontatistas",
              url: "/est/segcontatistas",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            }, {
              id: 5,
              spaces: true,
              titulo: "Control de siniestros especialesy servicios",
              clave: "siniestrosespeciales",
              url: "/est/siniestrosespeciales",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            }, {
              id: 5,
              spaces: true,
              titulo: "Seguimiento solicitudes de pago de equipo electronico y obra civil",
              clave: "pagoequipos",
              url: "/est/pagoequipos",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            }, {
              id: 5,
              spaces: true,
              titulo: "Reporte de montos reclmados CAPUFE",
              clave: "reclamadoscapufe",
              url: "/est/reclamadoscapufe",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            },],
          },
          {
            id: 5,
            spaces: true,
            titulo: "Siniestralidad",
            clave: "estsinestralidad",
            url: "/",
            win: "",
            icono: <FaChartPie />,
            submenu: true,
            submenus: [{
              id: 5,
              spaces: true,
              titulo: "Supervisión de sesiones quincenales",
              clave: "sesionesquincenales",
              url: "/est/sesionesquincenales",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            }, {
              id: 5,
              spaces: true,
              titulo: "Torre de control",
              clave: "torrecontrol",
              url: "/est/torrecontrol",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            }, {
              id: 5,
              spaces: true,
              titulo: "Analisis de siniestralidad",
              clave: "analisis",
              url: "/est/analisis",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            },],
          },
        ],
      },
      {
        id: 6,
        spaces: true,
        titulo: "Control carretero",
        clave: "controlcarretero",
        url: "/",
        win: "",
        icono: <FaRoute />,
        submenu: true,
        submenus: [{
          id: 6,
          spaces: true,
          titulo: "Control carretero",
          clave: "crlcarretero",
          url: "https://controlcarretero.com/gnp/login.aspx?ReturnUrl=%2fgnp%2fApplication%2fdashboard.aspx",
          win: "_blank",
          icono: <FaRoute />,
          submenu: false,
          submenus: [],
        },],
      },
/* CAPACITACION */
/*
{
  id: 98,
  spaces: true,
  titulo: "Capacitación",
  clave: "Capacitación",
  url: "/cap/capacitacion",
  win: "",
  icono: <PiStudent />,
  submenu: false,
  submenus: [],
},*/
      {
        id: 99,
        spaces: true,
        titulo: "Whatsapp",
        clave: "whatsap",
        url: "https://api.whatsapp.com/send?phone=5215578786789&amp;text=%C2%A1Hola%2C%20bienvenido%20al%20servicio%20de%20SINESTRY!%20%0AA%20trav%C3%A9s%20de%20este%20canal%2C%20podremos%20resolverte%20cualquier%20duda%20que%20tengas%20para%20la%20atenci%C3%B3n%20de%20siniestros.%0AEstamos%20aqu%C3%AD%20para%20ayudarte.%20%20%0A%C2%A1Gracias!",
        win: "_blank",
        icono: <IoLogoWhatsapp />,
        submenu: false,
        submenus: [],
      },
    ],
  },
  coordinadosinestry: {
    menus: [
      {
        id: 1,
        spaces: false,
        titulo: "Siniestralidad",
        clave: "siniestralidad",
        url: "/",
        win: "",
        icono: <PiNotepadBold />,
        submenu: true,
        submenus: [
          {
            id: 1,
            spaces: true,
            titulo: "2023",
            clave: "sin2023",
            url: "/",
            win: "",
            icono: <PiNotepadBold />,
            submenu: true,
            submenus: [
              {
                id: 1,
                spaces: true,
                titulo: "Tijuana",
                clave: "tijuana",
                url: "/sin/2023/tijuana",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 2,
                spaces: true,
                titulo: "Hermosillo",
                clave: "hermosillo",
                url: "/sin/2023/hermosillo",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 3,
                spaces: true,
                titulo: "Reynosa",
                clave: "reynosa",
                url: "/sin/2023/reynosa",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 4,
                spaces: true,
                titulo: "Saltillo",
                clave: "saltillo",
                url: "/sin/2023/saltillo",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 5,
                spaces: true,
                titulo: "Mazatlán",
                clave: "mazatlan",
                url: "/sin/2023/mazatlan",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Quéretaro",
                clave: "reynosa",
                url: "/sin/2023/queretaro",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 7,
                spaces: true,
                titulo: "Cuernavaca",
                clave: "cuernavaca",
                url: "/sin/2023/cuernavaca",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 2,
                spaces: true,
                titulo: "Puebla",
                clave: "puebla",
                url: "/sin/2023/puebla",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 3,
                spaces: true,
                titulo: "Veracruz",
                clave: "veracruz",
                url: "/sin/2023/veracruz",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 4,
                spaces: true,
                titulo: "Coatzacoalcos",
                clave: "coatzacoalcos",
                url: "/sin/2023/coatzacoalcos",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 5,
                spaces: true,
                titulo: "Estado de México",
                clave: "edomex",
                url: "/sin/2023/edomex",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Villahermosa",
                clave: "villahermosa",
                url: "/sin/2023/villahermosa",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Oaxaca",
                clave: "oaxaca",
                url: "/sin/2023/oaxaca",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
            ],
          },
          {
            id: 1,
            spaces: true,
            titulo: "2024",
            clave: "sin2024",
            url: "/",
            win: "",
            icono: <PiNotepadBold />,
            submenu: true,
            submenus: [
              {
                id: 1,
                spaces: true,
                titulo: "Tijuana",
                clave: "tijuana",
                url: "/sin/2024/tijuana",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 2,
                spaces: true,
                titulo: "Hermosillo",
                clave: "hermosillo",
                url: "/sin/2024/hermosillo",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 3,
                spaces: true,
                titulo: "Reynosa",
                clave: "reynosa",
                url: "/sin/2024/reynosa",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 4,
                spaces: true,
                titulo: "Saltillo",
                clave: "saltillo",
                url: "/sin/2024/saltillo",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 5,
                spaces: true,
                titulo: "Mazatlán",
                clave: "mazatlan",
                url: "/sin/2024/mazatlan",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Quéretaro",
                clave: "reynosa",
                url: "/sin/2024/queretaro",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 7,
                spaces: true,
                titulo: "Cuernavaca",
                clave: "cuernavaca",
                url: "/sin/2024/cuernavaca",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 2,
                spaces: true,
                titulo: "Puebla",
                clave: "puebla",
                url: "/sin/2024/puebla",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 3,
                spaces: true,
                titulo: "Veracruz",
                clave: "  veracruz",
                url: "/sin/2024/veracruz",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 4,
                spaces: true,
                titulo: "Coatzacoalcos",
                clave: "coatzacoalcos",
                url: "/sin/2024/coatzacoalcos",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 5,
                spaces: true,
                titulo: "Estado de México",
                clave: "edomex",
                url: "/sin/2024/edomex",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Villahermosa",
                clave: "villahermosa",
                url: "/sin/2024/villahermosa",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Oaxaca",
                 clave: "oaxaca",
                url: "/sin/2024/oaxaca",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
            ],
          },
        ],
      },

      {
        id: 2,
        spaces: true,
        titulo: "Control de facturas",
        clave: "ctrfacturas",
        url: "/",
        win: "",
        icono: <LiaFileInvoiceDollarSolid />,
        submenu: true,
        submenus: [{
          id: 2,
          spaces: true,
          titulo: "GNP-CAPUFE Control de facturas 2020-2022",
          clave: "gnp2024",
          url: "/ctf/controlfact2024",
          win: "",
          icono: <LiaFileInvoiceDollarSolid />,
          submenu: true,
          submenus: [],
        }, {
          id: 2,
          spaces: true,
          titulo: "GNP-CAPUFE Control de facturas 2022-2024",
          clave: "gnp2224",
          url: "/ctf/controlfact2224",
          win: "",
          icono: <LiaFileInvoiceDollarSolid />,
          submenu: true,
          submenus: [],
        }, {
          id: 2,
          spaces: true,
          titulo: "Control Sin.Mayores a un millón",
          clave: "gnpmillon",
          url: "/ctf/controlsinmill",
          win: "",
          icono: <LiaFileInvoiceDollarSolid />,
          submenu: true,
          submenus: [],
        },],
      },
      {
        id: 2,
        spaces: true,
        titulo: "Rentabilidad",
        clave: "rentabilidad",
        url: "/",
        win: "",
        icono: <GiProfit />,
        submenu: true,
        submenus: [{
          id: 2,
          spaces: true,
          titulo: "Aforo vehicular y primas cobradas",
          clave: "aforo",
          url: "/ren/aforo",
          win: "",
          icono: <GiProfit />,
          submenu: true,
          submenus: [],
        }, {
          id: 2,
          spaces: true,
          titulo: "Analisis de rentabilidad de montos 2020 al 2024",
          clave: "analisissin",
          url: "/ren/analisissin",
          win: "",
          icono: <GiProfit />,
          submenu: true,
          submenus: [],
        },],
      },
      {
        id: 3,
        spaces: false,
        titulo: "Cotizador",
        clave: "cotizador",
        url: "/",
        win: "",
        icono: <MdOutlineRequestQuote />,
        submenu: true,
        submenus: [{
          id: 2,
          spaces: false,
          titulo: "Cotizador",
          clave: "cotizador",
          url: "/cot/cotizador",
          win: "",
          icono: <MdOutlineRequestQuote />,
          submenu: false,
          submenus: [],
        },],
      },

      {
        id: 5,
        spaces: true,
        titulo: "Estadísticas",
        clave: "estadisticas",
        url: "/",
        win: "",
        icono: <FaChartPie />,
        submenu: true,
        submenus: [
          {
            id: 5,
            spaces: true,
            titulo: "Control carretero",
            clave: "estcontrolcarretero",
            url: "/",
            win: "",
            icono: <FaChartPie />,
            submenu: true,
            submenus: [{
              id: 5,
              spaces: true,
              titulo: "Seguimiento contratistas",
              clave: "segcontatistas",
              url: "/est/segcontatistas",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            },],
          },
          {
            id: 5,
            spaces: true,
            titulo: "Control de facturas",
            clave: "estcontfacturas",
            url: "/",
            win: "",
            icono: <FaChartPie />,
            submenu: true,
            submenus: [{
              id: 5,
              spaces: true,
              titulo: "Seguimiento contratistas",
              clave: "segcontatistas",
              url: "/est/segcontatistas",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            }, {
              id: 5,
              spaces: true,
              titulo: "Control de siniestros especialesy servicios",
              clave: "siniestrosespeciales",
              url: "/est/siniestrosespeciales",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            }, {
              id: 5,
              spaces: true,
              titulo: "Seguimiento solicitudes de pago de equipo electronico y obra civil",
              clave: "pagoequipos",
              url: "/est/pagoequipos",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            }, {
              id: 5,
              spaces: true,
              titulo: "Reporte de montos reclmados CAPUFE",
              clave: "reclamadoscapufe",
              url: "/est/reclamadoscapufe",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            },],
          },
          {
            id: 5,
            spaces: true,
            titulo: "Siniestralidad",
            clave: "estsinestralidad",
            url: "/",
            win: "",
            icono: <FaChartPie />,
            submenu: true,
            submenus: [{
              id: 5,
              spaces: true,
              titulo: "Supervisión de sesiones quincenales",
              clave: "sesionesquincenales",
              url: "/est/sesionesquincenales",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            }, {
              id: 5,
              spaces: true,
              titulo: "Torre de control",
              clave: "torrecontrol",
              url: "/est/torrecontrol",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            }, {
              id: 5,
              spaces: true,
              titulo: "Analisis de siniestralidad",
              clave: "analisis",
              url: "/est/analisis",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            },],
          },
        ],
      },
      {
        id: 6,
        spaces: true,
        titulo: "Control carretero",
        clave: "controlcarretero",
        url: "/",
        win: "",
        icono: <FaRoute />,
        submenu: true,
        submenus: [{
          id: 6,
          spaces: true,
          titulo: "Control carretero",
          clave: "crlcarretero",
          url: "https://controlcarretero.com/gnp/login.aspx?ReturnUrl=%2fgnp%2fApplication%2fdashboard.aspx",
          win: "_blank",
          icono: <FaRoute />,
          submenu: false,
          submenus: [],
        },],
      },
/* CAPACITACION */
/*
{
  id: 98,
  spaces: true,
  titulo: "Capacitación",
  clave: "Capacitación",
  url: "/cap/capacitacion",
  win: "",
  icono: <PiStudent />,
  submenu: false,
  submenus: [],
},*/
      {
        id: 99,
        spaces: true,
        titulo: "Whatsapp",
        clave: "whatsap",
        url: "https://api.whatsapp.com/send?phone=5215578786789&amp;text=%C2%A1Hola%2C%20bienvenido%20al%20servicio%20de%20SINESTRY!%20%0AA%20trav%C3%A9s%20de%20este%20canal%2C%20podremos%20resolverte%20cualquier%20duda%20que%20tengas%20para%20la%20atenci%C3%B3n%20de%20siniestros.%0AEstamos%20aqu%C3%AD%20para%20ayudarte.%20%20%0A%C2%A1Gracias!",
        win: "_blank",
        icono: <IoLogoWhatsapp />,
        submenu: false,
        submenus: [],
      },
    ],
  },
  operativosinestry: {
    menus: [
      {
        id: 1,
        spaces: false,
        titulo: "Siniestralidad",
        clave: "siniestralidad",
        url: "/",
        win: "",
        icono: <PiNotepadBold />,
        submenu: true,
        submenus: [
          {
            id: 1,
            spaces: true,
            titulo: "2023",
            clave: "sin2023",
            url: "/",
            win: "",
            icono: <PiNotepadBold />,
            submenu: true,
            submenus: [
              {
                id: 1,
                spaces: true,
                titulo: "Tijuana",
                clave: "tijuana",
                url: "/sin/2023/tijuana",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 2,
                spaces: true,
                titulo: "Hermosillo",
                clave: "hermosillo",
                url: "/sin/2023/hermosillo",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 3,
                spaces: true,
                titulo: "Reynosa",
                clave: "reynosa",
                url: "/sin/2023/reynosa",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 4,
                spaces: true,
                titulo: "Saltillo",
                clave: "saltillo",
                url: "/sin/2023/saltillo",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 5,
                spaces: true,
                titulo: "Mazatlán",
                clave: "mazatlan",
                url: "/sin/2023/mazatlan",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Quéretaro",
                clave: "reynosa",
                url: "/sin/2023/queretaro",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 7,
                spaces: true,
                titulo: "Cuernavaca",
                clave: "cuernavaca",
                url: "/sin/2023/cuernavaca",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 2,
                spaces: true,
                titulo: "Puebla",
                clave: "puebla",
                url: "/sin/2023/puebla",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 3,
                spaces: true,
                titulo: "Veracruz",
                clave: "veracruz",
                url: "/sin/2023/veracruz",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 4,
                spaces: true,
                titulo: "Coatzacoalcos",
                clave: "coatzacoalcos",
                url: "/sin/2023/coatzacoalcos",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 5,
                spaces: true,
                titulo: "Estado de México",
                clave: "edomex",
                url: "/sin/2023/edomex",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Villahermosa",
                clave: "villahermosa",
                url: "/sin/2023/villahermosa",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Oaxaca",
                clave: "oaxaca",
                url: "/sin/2023/oaxaca",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
            ],
          },
          {
            id: 1,
            spaces: true,
            titulo: "2024",
            clave: "sin2024",
            url: "/",
            win: "",
            icono: <PiNotepadBold />,
            submenu: true,
            submenus: [
              {
                id: 1,
                spaces: true,
                titulo: "Tijuana",
                clave: "tijuana",
                url: "/sin/2024/tijuana",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 2,
                spaces: true,
                titulo: "Hermosillo",
                clave: "hermosillo",
                url: "/sin/2024/hermosillo",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 3,
                spaces: true,
                titulo: "Reynosa",
                clave: "reynosa",
                url: "/sin/2024/reynosa",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 4,
                spaces: true,
                titulo: "Saltillo",
                clave: "saltillo",
                url: "/sin/2024/saltillo",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 5,
                spaces: true,
                titulo: "Mazatlán",
                clave: "mazatlan",
                url: "/sin/2024/mazatlan",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Quéretaro",
                clave: "reynosa",
                url: "/sin/2024/queretaro",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 7,
                spaces: true,
                titulo: "Cuernavaca",
                clave: "cuernavaca",
                url: "/sin/2024/cuernavaca",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 2,
                spaces: true,
                titulo: "Puebla",
                clave: "puebla",
                url: "/sin/2024/puebla",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 3,
                spaces: true,
                titulo: "Veracruz",
                clave: "  veracruz",
                url: "/sin/2024/veracruz",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 4,
                spaces: true,
                titulo: "Coatzacoalcos",
                clave: "coatzacoalcos",
                url: "/sin/2024/coatzacoalcos",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 5,
                spaces: true,
                titulo: "Estado de México",
                clave: "edomex",
                url: "/sin/2024/edomex",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Villahermosa",
                clave: "villahermosa",
                url: "/sin/2024/villahermosa",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Oaxaca",
                clave: "oaxaca",
                url: "/sin/2024/oaxaca",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
            ],
          },
        ],
      },

      {
        id: 2,
        spaces: true,
        titulo: "Control de facturas",
        clave: "ctrfacturas",
        url: "/",
        win: "",
        icono: <LiaFileInvoiceDollarSolid />,
        submenu: true,
        submenus: [{
          id: 2,
          spaces: true,
          titulo: "GNP-CAPUFE Control de facturas 2020-2022",
          clave: "gnp2024",
          url: "/ctf/controlfact2024",
          win: "",
          icono: <LiaFileInvoiceDollarSolid />,
          submenu: true,
          submenus: [],
        }, {
          id: 2,
          spaces: true,
          titulo: "GNP-CAPUFE Control de facturas 2022-2024",
          clave: "gnp2224",
          url: "/ctf/controlfact2224",
          win: "",
          icono: <LiaFileInvoiceDollarSolid />,
          submenu: true,
          submenus: [],
        }, {
          id: 2,
          spaces: true,
          titulo: "Control Sin.Mayores a un millón",
          clave: "gnpmillon",
          url: "/ctf/controlsinmill",
          win: "",
          icono: <LiaFileInvoiceDollarSolid />,
          submenu: true,
          submenus: [],
        },],
      },
      {
        id: 2,
        spaces: true,
        titulo: "Rentabilidad",
        clave: "rentabilidad",
        url: "/",
        win: "",
        icono: <GiProfit />,
        submenu: true,
        submenus: [{
          id: 2,
          spaces: true,
          titulo: "Aforo vehicular y primas cobradas",
          clave: "aforo",
          url: "/ren/aforo",
          win: "",
          icono: <GiProfit />,
          submenu: true,
          submenus: [],
        }, {
          id: 2,
          spaces: true,
          titulo: "Analisis de rentabilidad de montos 2020 al 2024",
          clave: "analisissin",
          url: "/ren/analisissin",
          win: "",
          icono: <GiProfit />,
          submenu: true,
          submenus: [],
        },],
      },
      {
        id: 3,
        spaces: false,
        titulo: "Cotizador",
        clave: "cotizador",
        url: "/",
        win: "",
        icono: <MdOutlineRequestQuote />,
        submenu: true,
        submenus: [{
          id: 2,
          spaces: false,
          titulo: "Cotizador",
          clave: "cotizador",
          url: "/cot/cotizador",
          win: "",
          icono: <MdOutlineRequestQuote />,
          submenu: false,
          submenus: [],
        },],
      },

      {
        id: 5,
        spaces: true,
        titulo: "Estadísticas",
        clave: "estadisticas",
        url: "/",
        win: "",
        icono: <FaChartPie />,
        submenu: true,
        submenus: [
          {
            id: 5,
            spaces: true,
            titulo: "Control carretero",
            clave: "estcontrolcarretero",
            url: "/",
            win: "",
            icono: <FaChartPie />,
            submenu: true,
            submenus: [{
              id: 5,
              spaces: true,
              titulo: "Seguimiento contratistas",
              clave: "segcontatistas",
              url: "/est/segcontatistas",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            },],
          },
          {
            id: 5,
            spaces: true,
            titulo: "Control de facturas",
            clave: "estcontfacturas",
            url: "/",
            win: "",
            icono: <FaChartPie />,
            submenu: true,
            submenus: [{
              id: 5,
              spaces: true,
              titulo: "Seguimiento contratistas",
              clave: "segcontatistas",
              url: "/est/segcontatistas",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            }, {
              id: 5,
              spaces: true,
              titulo: "Control de siniestros especialesy servicios",
              clave: "siniestrosespeciales",
              url: "/est/siniestrosespeciales",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            }, {
              id: 5,
              spaces: true,
              titulo: "Seguimiento solicitudes de pago de equipo electronico y obra civil",
              clave: "pagoequipos",
              url: "/est/pagoequipos",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            }, {
              id: 5,
              spaces: true,
              titulo: "Reporte de montos reclmados CAPUFE",
              clave: "reclamadoscapufe",
              url: "/est/reclamadoscapufe",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            },],
          },
          {
            id: 5,
            spaces: true,
            titulo: "Siniestralidad",
            clave: "estsinestralidad",
            url: "/",
            win: "",
            icono: <FaChartPie />,
            submenu: true,
            submenus: [{
              id: 5,
              spaces: true,
              titulo: "Supervisión de sesiones quincenales",
              clave: "sesionesquincenales",
              url: "/est/sesionesquincenales",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            }, {
              id: 5,
              spaces: true,
              titulo: "Torre de control",
              clave: "torrecontrol",
              url: "/est/torrecontrol",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            }, {
              id: 5,
              spaces: true,
              titulo: "Analisis de siniestralidad",
              clave: "analisis",
              url: "/est/analisis",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            },],
          },
        ],
      },
      {
        id: 6,
        spaces: true,
        titulo: "Control carretero",
        clave: "controlcarretero",
        url: "/",
        win: "",
        icono: <FaRoute />,
        submenu: true,
        submenus: [{
          id: 6,
          spaces: true,
          titulo: "Control carretero",
          clave: "crlcarretero",
          url: "https://controlcarretero.com/gnp/login.aspx?ReturnUrl=%2fgnp%2fApplication%2fdashboard.aspx",
          win: "_blank",
          icono: <FaRoute />,
          submenu: false,
          submenus: [],
        },],
      },
/* CAPACITACION *//*
      {
        id: 98,
        spaces: true,
        titulo: "Capacitación",
        clave: "Capacitación",
        url: "/cap/capacitacion",
        win: "",
        icono: <PiStudent />,
        submenu: false,
        submenus: [],
      },*/
      {
        id: 99,
        spaces: true,
        titulo: "Whatsapp",
        clave: "whatsap",
        url: "https://api.whatsapp.com/send?phone=5215578786789&amp;text=%C2%A1Hola%2C%20bienvenido%20al%20servicio%20de%20SINESTRY!%20%0AA%20trav%C3%A9s%20de%20este%20canal%2C%20podremos%20resolverte%20cualquier%20duda%20que%20tengas%20para%20la%20atenci%C3%B3n%20de%20siniestros.%0AEstamos%20aqu%C3%AD%20para%20ayudarte.%20%20%0A%C2%A1Gracias!",
        win: "_blank",
        icono: <IoLogoWhatsapp />,
        submenu: false,
        submenus: [],
      },
    ],
  },
  torrecontrol: {
    menus: [
      // Siniestrlidad
      {
        id: 1,
        spaces: false,
        titulo: "Siniestralidad",
        clave: "siniestralidad",
        url: "/",
        win: "",
        icono: <PiNotepadBold />,
        submenu: true,
        submenus: [
          {
            id: 1,
            spaces: true,
            titulo: "2023",
            clave: "sin2023",
            url: "/",
            win: "",
            icono: <PiNotepadBold />,
            submenu: true,
            submenus: [
              {
                id: 1,
                spaces: true,
                titulo: "Tijuana",
                clave: "tijuana",
                url: "/sin/2023/tijuana",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 2,
                spaces: true,
                titulo: "Hermosillo",
                clave: "hermosillo",
                url: "/sin/2023/hermosillo",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 3,
                spaces: true,
                titulo: "Reynosa",
                clave: "reynosa",
                url: "/sin/2023/reynosa",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 4,
                spaces: true,
                titulo: "Saltillo",
                clave: "saltillo",
                url: "/sin/2023/saltillo",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 5,
                spaces: true,
                titulo: "Mazatlán",
                clave: "mazatlan",
                url: "/sin/2023/mazatlan",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Quéretaro",
                clave: "reynosa",
                url: "/sin/2023/queretaro",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 7,
                spaces: true,
                titulo: "Cuernavaca",
                clave: "cuernavaca",
                url: "/sin/2023/cuernavaca",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 2,
                spaces: true,
                titulo: "Puebla",
                clave: "puebla",
                url: "/sin/2023/puebla",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 3,
                spaces: true,
                titulo: "Veracruz",
                clave: "veracruz",
                url: "/sin/2023/veracruz",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 4,
                spaces: true,
                titulo: "Coatzacoalcos",
                clave: "coatzacoalcos",
                url: "/sin/2023/coatzacoalcos",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 5,
                spaces: true,
                titulo: "Estado de México",
                clave: "edomex",
                url: "/sin/2023/edomex",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Villahermosa",
                clave: "villahermosa",
                url: "/sin/2023/villahermosa",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Oaxaca",
                clave: "oaxaca",
                url: "/sin/2023/oaxaca",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
            ],
          },
          {
            id: 1,
            spaces: true,
            titulo: "2024",
            clave: "sin2024",
            url: "/",
            win: "",
            icono: <PiNotepadBold />,
            submenu: true,
            submenus: [
              {
                id: 1,
                spaces: true,
                titulo: "Tijuana",
                clave: "tijuana",
                url: "/sin/2024/tijuana",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 2,
                spaces: true,
                titulo: "Hermosillo",
                clave: "hermosillo",
                url: "/sin/2024/hermosillo",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 3,
                spaces: true,
                titulo: "Reynosa",
                clave: "reynosa",
                url: "/sin/2024/reynosa",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 4,
                spaces: true,
                titulo: "Saltillo",
                clave: "saltillo",
                url: "/sin/2024/saltillo",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 5,
                spaces: true,
                titulo: "Mazatlán",
                clave: "mazatlan",
                url: "/sin/2024/mazatlan",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Quéretaro",
                clave: "reynosa",
                url: "/sin/2024/queretaro",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 7,
                spaces: true,
                titulo: "Cuernavaca",
                clave: "cuernavaca",
                url: "/sin/2024/cuernavaca",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 2,
                spaces: true,
                titulo: "Puebla",
                clave: "puebla",
                url: "/sin/2024/puebla",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 3,
                spaces: true,
                titulo: "Veracruz",
                clave: "  veracruz",
                url: "/sin/2024/veracruz",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 4,
                spaces: true,
                titulo: "Coatzacoalcos",
                clave: "coatzacoalcos",
                url: "/sin/2024/coatzacoalcos",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 5,
                spaces: true,
                titulo: "Estado de México",
                clave: "edomex",
                url: "/sin/2024/edomex",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Villahermosa",
                clave: "villahermosa",
                url: "/sin/2024/villahermosa",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Oaxaca",
                clave: "oaxaca",
                url: "/sin/2024/oaxaca",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
            ],
          },
        ],
      },
      // Estadisticas
      {
        id: 5,
        spaces: true,
        titulo: "Estadísticas",
        clave: "estadisticas",
        url: "/",
        win: "",
        icono: <FaChartPie />,
        submenu: true,
        submenus: [
          
          {
            id: 5,
            spaces: true,
            titulo: "Siniestralidad",
            clave: "estsinestralidad",
            url: "/",
            win: "",
            icono: <FaChartPie />,
            submenu: true,
            submenus: [ {
              id: 5,
              spaces: true,
              titulo: "Analisis de siniestralidad",
              clave: "analisis",
              url: "/est/analisis",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            }, 
          ],
          },
        ],
      },      
      // Control caretero
      {
        id: 6,
        spaces: true,
        titulo: "Control carretero",
        clave: "controlcarretero",
        url: "/",
        win: "",
        icono: <FaRoute />,
        submenu: true,
        submenus: [{
          id: 6,
          spaces: true,
          titulo: "Control carretero",
          clave: "crlcarretero",
          url: "https://controlcarretero.com/gnp/login.aspx?ReturnUrl=%2fgnp%2fApplication%2fdashboard.aspx",
          win: "_blank",
          icono: <FaRoute />,
          submenu: false,
          submenus: [],
        },],
      },
      /* Capacitacion */
      /*{
        id: 98,
        spaces: true,
        titulo: "Capacitación",
        clave: "Capacitación",
        url: "/cap/capacitacion",
        win: "",
        icono: <PiStudent />,
        submenu: false,
        submenus: [],
      },*/] 
  },
  /* */
  usuariognp: {
    menus: [
      // siniestralidad
      {
        id: 1,
        spaces: false,
        titulo: "Siniestralidad",
        clave: "siniestralidad",
        url: "/",
        win: "",
        icono: <PiNotepadBold />,
        submenu: true,
        submenus: [
          {
            id: 1,
            spaces: true,
            titulo: "2023",
            clave: "sin2023",
            url: "/",
            win: "",
            icono: <PiNotepadBold />,
            submenu: true,
            submenus: [
              {
                id: 1,
                spaces: true,
                titulo: "Tijuana",
                clave: "tijuana",
                url: "/sin/2023/tijuana",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 2,
                spaces: true,
                titulo: "Hermosillo",
                clave: "hermosillo",
                url: "/sin/2023/hermosillo",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 3,
                spaces: true,
                titulo: "Reynosa",
                clave: "reynosa",
                url: "/sin/2023/reynosa",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 4,
                spaces: true,
                titulo: "Saltillo",
                clave: "saltillo",
                url: "/sin/2023/saltillo",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 5,
                spaces: true,
                titulo: "Mazatlán",
                clave: "mazatlan",
                url: "/sin/2023/mazatlan",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Quéretaro",
                clave: "reynosa",
                url: "/sin/2023/queretaro",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 7,
                spaces: true,
                titulo: "Cuernavaca",
                clave: "cuernavaca",
                url: "/sin/2023/cuernavaca",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 2,
                spaces: true,
                titulo: "Puebla",
                clave: "puebla",
                url: "/sin/2023/puebla",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 3,
                spaces: true,
                titulo: "Veracruz",
                clave: "veracruz",
                url: "/sin/2023/veracruz",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 4,
                spaces: true,
                titulo: "Coatzacoalcos",
                clave: "coatzacoalcos",
                url: "/sin/2023/coatzacoalcos",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 5,
                spaces: true,
                titulo: "Estado de México",
                clave: "edomex",
                url: "/sin/2023/edomex",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Villahermosa",
                clave: "villahermosa",
                url: "/sin/2023/villahermosa",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Oaxaca",
                clave: "oaxaca",
                url: "/sin/2023/oaxaca",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
            ],
          },
          {
            id: 1,
            spaces: true,
            titulo: "2024",
            clave: "sin2024",
            url: "/",
            win: "",
            icono: <PiNotepadBold />,
            submenu: true,
            submenus: [
              {
                id: 1,
                spaces: true,
                titulo: "Tijuana",
                clave: "tijuana",
                url: "/sin/2024/tijuana",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 2,
                spaces: true,
                titulo: "Hermosillo",
                clave: "hermosillo",
                url: "/sin/2024/hermosillo",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 3,
                spaces: true,
                titulo: "Reynosa",
                clave: "reynosa",
                url: "/sin/2024/reynosa",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 4,
                spaces: true,
                titulo: "Saltillo",
                clave: "saltillo",
                url: "/sin/2024/saltillo",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 5,
                spaces: true,
                titulo: "Mazatlán",
                clave: "mazatlan",
                url: "/sin/2024/mazatlan",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Quéretaro",
                clave: "reynosa",
                url: "/sin/2024/queretaro",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 7,
                spaces: true,
                titulo: "Cuernavaca",
                clave: "cuernavaca",
                url: "/sin/2024/cuernavaca",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 2,
                spaces: true,
                titulo: "Puebla",
                clave: "puebla",
                url: "/sin/2024/puebla",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 3,
                spaces: true,
                titulo: "Veracruz",
                clave: "  veracruz",
                url: "/sin/2024/veracruz",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 4,
                spaces: true,
                titulo: "Coatzacoalcos",
                clave: "coatzacoalcos",
                url: "/sin/2024/coatzacoalcos",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 5,
                spaces: true,
                titulo: "Estado de México",
                clave: "edomex",
                url: "/sin/2024/edomex",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Villahermosa",
                clave: "villahermosa",
                url: "/sin/2024/villahermosa",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Oaxaca",
                clave: "oaxaca",
                url: "/sin/2024/oaxaca",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
            ],
          },
        ],
      },
      // Cotizador
      {
        id: 3,
        spaces: false,
        titulo: "Cotizador",
        clave: "cotizador",
        url: "/",
        win: "",
        icono: <MdOutlineRequestQuote />,
        submenu: true,
        submenus: [{
          id: 2,
          spaces: false,
          titulo: "Cotizador",
          clave: "cotizador",
          url: "/cot/cotizador",
          win: "",
          icono: <MdOutlineRequestQuote />,
          submenu: false,
          submenus: [],
        },],
      },
      /* CONTROL DE FACTURAS */ 
      {
        id: 2,
        spaces: true,
        titulo: "Control de facturas",
        clave: "ctrfacturas",
        url: "/",
        win: "",
        icono: <LiaFileInvoiceDollarSolid />,
        submenu: true,
        submenus: [ {
          id: 2,
          spaces: true,
          titulo: "Control Sin.Mayores a un millón",
          clave: "gnpmillon",
          url: "/ctf/controlsinmill",
          win: "",
          icono: <LiaFileInvoiceDollarSolid />,
          submenu: true,
          submenus: [],
        },],
      },
     /* ESTADISTICAS */
      {
        id: 5,
        spaces: true,
        titulo: "Estadísticas",
        clave: "estadisticas",
        url: "/",
        win: "",
        icono: <FaChartPie />,
        submenu: true,
        submenus: [
          {
            id: 5,
            spaces: true,
            titulo: "Siniestralidad",
            clave: "estsinestralidad",
            url: "/",
            win: "",
            icono: <FaChartPie />,
            submenu: true,
            submenus: [{
              id: 5,
              spaces: true,
              titulo: "Supervisión de sesiones quincenales",
              clave: "sesionesquincenales",
              url: "/est/sesionesquincenales",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            },{
              id: 5,
              spaces: true,
              titulo: "Siniestralidad reportes para siniestros especiales",
              clave: "siniestrosespeciales",
              url: "/est/siniestrosespeciales",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            }, {
              id: 5,
              spaces: true,
              titulo: "Siniestralidad reportes de montos reclamados por CAPUFE",
              clave: "reclamadoscapufe",
              url: "/est/reclamadoscapufe",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            },],
          },
        ],
      },
      /* CONTROL CARRETERO*/
      {
        id: 6,
        spaces: true,
        titulo: "Control carretero",
        clave: "controlcarretero",
        url: "/",
        win: "",
        icono: <FaRoute />,
        submenu: true,
        submenus: [{
          id: 6,
          spaces: true,
          titulo: "Control carretero",
          clave: "crlcarretero",
          url: "https://controlcarretero.com/gnp/login.aspx?ReturnUrl=%2fgnp%2fApplication%2fdashboard.aspx",
          win: "_blank",
          icono: <FaRoute />,
          submenu: false,
          submenus: [],
        },],
      },
     /* CAPACITACION */
     {
      id: 98,
      spaces: true,
      titulo: "Capacitación",
      clave: "Capacitación",
      url: "/cap/capacitacion",
      win: "",
      icono: <PiStudent />,
      submenu: false,
      submenus: [],
    },
      {
        id: 99,
        spaces: true,
        titulo: "Whatsapp",
        clave: "whatsap",
        url: "https://api.whatsapp.com/send?phone=5215578786789&amp;text=%C2%A1Hola%2C%20bienvenido%20al%20servicio%20de%20SINESTRY!%20%0AA%20trav%C3%A9s%20de%20este%20canal%2C%20podremos%20resolverte%20cualquier%20duda%20que%20tengas%20para%20la%20atenci%C3%B3n%20de%20siniestros.%0AEstamos%20aqu%C3%AD%20para%20ayudarte.%20%20%0A%C2%A1Gracias!",
        win: "_blank",
        icono: <IoLogoWhatsapp />,
        submenu: false,
        submenus: [],
      },
    ],
  },

  /* gnp */
  gerentegnp: {
    menus: [
      // siniestralidad
      {
        id: 1,
        spaces: false,
        titulo: "Siniestralidad",
        clave: "siniestralidad",
        url: "/",
        win: "",
        icono: <PiNotepadBold />,
        submenu: true,
        submenus: [
          {
            id: 1,
            spaces: true,
            titulo: "2023",
            clave: "sin2023",
            url: "/",
            win: "",
            icono: <PiNotepadBold />,
            submenu: true,
            submenus: [
              {
                id: 1,
                spaces: true,
                titulo: "Tijuana",
                clave: "tijuana",
                url: "/sin/2023/tijuana",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 2,
                spaces: true,
                titulo: "Hermosillo",
                clave: "hermosillo",
                url: "/sin/2023/hermosillo",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 3,
                spaces: true,
                titulo: "Reynosa",
                clave: "reynosa",
                url: "/sin/2023/reynosa",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 4,
                spaces: true,
                titulo: "Saltillo",
                clave: "saltillo",
                url: "/sin/2023/saltillo",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 5,
                spaces: true,
                titulo: "Mazatlán",
                clave: "mazatlan",
                url: "/sin/2023/mazatlan",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Quéretaro",
                clave: "reynosa",
                url: "/sin/2023/queretaro",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 7,
                spaces: true,
                titulo: "Cuernavaca",
                clave: "cuernavaca",
                url: "/sin/2023/cuernavaca",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 2,
                spaces: true,
                titulo: "Puebla",
                clave: "puebla",
                url: "/sin/2023/puebla",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 3,
                spaces: true,
                titulo: "Veracruz",
                clave: "veracruz",
                url: "/sin/2023/veracruz",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 4,
                spaces: true,
                titulo: "Coatzacoalcos",
                clave: "coatzacoalcos",
                url: "/sin/2023/coatzacoalcos",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 5,
                spaces: true,
                titulo: "Estado de México",
                clave: "edomex",
                url: "/sin/2023/edomex",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Villahermosa",
                clave: "villahermosa",
                url: "/sin/2023/villahermosa",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Oaxaca",
                clave: "oaxaca",
                url: "/sin/2023/oaxaca",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
            ],
          },
          {
            id: 1,
            spaces: true,
            titulo: "2024",
            clave: "sin2024",
            url: "/",
            win: "",
            icono: <PiNotepadBold />,
            submenu: true,
            submenus: [
              {
                id: 1,
                spaces: true,
                titulo: "Tijuana",
                clave: "tijuana",
                url: "/sin/2024/tijuana",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 2,
                spaces: true,
                titulo: "Hermosillo",
                clave: "hermosillo",
                url: "/sin/2024/hermosillo",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 3,
                spaces: true,
                titulo: "Reynosa",
                clave: "reynosa",
                url: "/sin/2024/reynosa",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 4,
                spaces: true,
                titulo: "Saltillo",
                clave: "saltillo",
                url: "/sin/2024/saltillo",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 5,
                spaces: true,
                titulo: "Mazatlán",
                clave: "mazatlan",
                url: "/sin/2024/mazatlan",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Quéretaro",
                clave: "reynosa",
                url: "/sin/2024/queretaro",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 7,
                spaces: true,
                titulo: "Cuernavaca",
                clave: "cuernavaca",
                url: "/sin/2024/cuernavaca",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 2,
                spaces: true,
                titulo: "Puebla",
                clave: "puebla",
                url: "/sin/2024/puebla",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 3,
                spaces: true,
                titulo: "Veracruz",
                clave: "  veracruz",
                url: "/sin/2024/veracruz",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 4,
                spaces: true,
                titulo: "Coatzacoalcos",
                clave: "coatzacoalcos",
                url: "/sin/2024/coatzacoalcos",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 5,
                spaces: true,
                titulo: "Estado de México",
                clave: "edomex",
                url: "/sin/2024/edomex",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Villahermosa",
                clave: "villahermosa",
                url: "/sin/2024/villahermosa",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Oaxaca",
                clave: "oaxaca",
                url: "/sin/2024/oaxaca",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
            ],
          },
        ],
      },
      // Cotizador
      {
        id: 3,
        spaces: false,
        titulo: "Cotizador",
        clave: "cotizador",
        url: "/",
        win: "",
        icono: <MdOutlineRequestQuote />,
        submenu: true,
        submenus: [{
          id: 2,
          spaces: false,
          titulo: "Cotizador",
          clave: "cotizador",
          url: "/cot/cotizador",
          win: "",
          icono: <MdOutlineRequestQuote />,
          submenu: false,
          submenus: [],
        },],
      },
      // control de facturas    
      {
        id: 2,
        spaces: true,
        titulo: "Control de facturas",
        clave: "ctrfacturas",
        url: "/",
        win: "",
        icono: <LiaFileInvoiceDollarSolid />,
        submenu: true,
        submenus: [ {
          id: 2,
          spaces: true,
          titulo: "Control Sin.Mayores a un millón",
          clave: "gnpmillon",
          url: "/ctf/controlsinmill",
          win: "",
          icono: <LiaFileInvoiceDollarSolid />,
          submenu: true,
          submenus: [],
        },],
      },
      // Estadisticas
      {
        id: 5,
        spaces: true,
        titulo: "Estadísticas",
        clave: "estadisticas",
        url: "/",
        win: "",
        icono: <FaChartPie />,
        submenu: true,
        submenus: [
          {
            id: 5,
            spaces: true,
            titulo: "Siniestralidad",
            clave: "estsinestralidad",
            url: "/",
            win: "",
            icono: <FaChartPie />,
            submenu: true,
            submenus: [{
              id: 5,
              spaces: true,
              titulo: "Supervisión de sesiones quincenales",
              clave: "sesionesquincenales",
              url: "/est/sesionesquincenales",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            },{
              id: 5,
              spaces: true,
              titulo: "Siniestralidad reportes para siniestros especiales",
              clave: "siniestrosespeciales",
              url: "/est/siniestrosespeciales",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            }, {
              id: 5,
              spaces: true,
              titulo: "Siniestralidad reportes de montos reclamados por CAPUFE",
              clave: "reclamadoscapufe",
              url: "/est/reclamadoscapufe",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            },],
          },
        ],
      },
      {
        id: 6,
        spaces: true,
        titulo: "Control carretero",
        clave: "controlcarretero",
        url: "/",
        win: "",
        icono: <FaRoute />,
        submenu: true,
        submenus: [{
          id: 6,
          spaces: true,
          titulo: "Control carretero",
          clave: "crlcarretero",
          url: "https://controlcarretero.com/gnp/login.aspx?ReturnUrl=%2fgnp%2fApplication%2fdashboard.aspx",
          win: "_blank",
          icono: <FaRoute />,
          submenu: false,
          submenus: [],
        },],
      },
/* CAPACITACION */
/*
{
  id: 98,
  spaces: true,
  titulo: "Capacitación",
  clave: "Capacitación",
  url: "/cap/capacitacion",
  win: "",
  icono: <PiStudent />,
  submenu: false,
  submenus: [],
},*/
      {
        id: 99,
        spaces: true,
        titulo: "Whatsapp",
        clave: "whatsap",
        url: "https://api.whatsapp.com/send?phone=5215578786789&amp;text=%C2%A1Hola%2C%20bienvenido%20al%20servicio%20de%20SINESTRY!%20%0AA%20trav%C3%A9s%20de%20este%20canal%2C%20podremos%20resolverte%20cualquier%20duda%20que%20tengas%20para%20la%20atenci%C3%B3n%20de%20siniestros.%0AEstamos%20aqu%C3%AD%20para%20ayudarte.%20%20%0A%C2%A1Gracias!",
        win: "_blank",
        icono: <IoLogoWhatsapp />,
        submenu: false,
        submenus: [],
      },
    ],
  },
  coordinadorgnp: {
    menus: [
      {
        id: 1,
        spaces: false,
        titulo: "Siniestralidad",
        clave: "siniestralidad",
        url: "/",
        win: "",
        icono: <PiNotepadBold />,
        submenu: true,
        submenus: [
          {
            id: 1,
            spaces: true,
            titulo: "2023",
            clave: "sin2023",
            url: "/",
            win: "",
            icono: <PiNotepadBold />,
            submenu: true,
            submenus: [
              {
                id: 1,
                spaces: true,
                titulo: "Tijuana",
                clave: "tijuana",
                url: "/sin/2023/tijuana",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 2,
                spaces: true,
                titulo: "Hermosillo",
                clave: "hermosillo",
                url: "/sin/2023/hermosillo",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 3,
                spaces: true,
                titulo: "Reynosa",
                clave: "reynosa",
                url: "/sin/2023/reynosa",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 4,
                spaces: true,
                titulo: "Saltillo",
                clave: "saltillo",
                url: "/sin/2023/saltillo",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 5,
                spaces: true,
                titulo: "Mazatlán",
                clave: "mazatlan",
                url: "/sin/2023/mazatlan",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Quéretaro",
                clave: "reynosa",
                url: "/sin/2023/queretaro",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 7,
                spaces: true,
                titulo: "Cuernavaca",
                clave: "cuernavaca",
                url: "/sin/2023/cuernavaca",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 2,
                spaces: true,
                titulo: "Puebla",
                clave: "puebla",
                url: "/sin/2023/puebla",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 3,
                spaces: true,
                titulo: "Veracruz",
                clave: "veracruz",
                url: "/sin/2023/veracruz",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 4,
                spaces: true,
                titulo: "Coatzacoalcos",
                clave: "coatzacoalcos",
                url: "/sin/2023/coatzacoalcos",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 5,
                spaces: true,
                titulo: "Estado de México",
                clave: "edomex",
                url: "/sin/2023/edomex",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Villahermosa",
                clave: "villahermosa",
                url: "/sin/2023/villahermosa",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Oaxaca",
                clave: "oaxaca",
                url: "/sin/2023/oaxaca",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
            ],
          },
          {
            id: 1,
            spaces: true,
            titulo: "2024",
            clave: "sin2024",
            url: "/",
            win: "",
            icono: <PiNotepadBold />,
            submenu: true,
            submenus: [
              {
                id: 1,
                spaces: true,
                titulo: "Tijuana",
                clave: "tijuana",
                url: "/sin/2024/tijuana",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 2,
                spaces: true,
                titulo: "Hermosillo",
                clave: "hermosillo",
                url: "/sin/2024/hermosillo",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 3,
                spaces: true,
                titulo: "Reynosa",
                clave: "reynosa",
                url: "/sin/2024/reynosa",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 4,
                spaces: true,
                titulo: "Saltillo",
                clave: "saltillo",
                url: "/sin/2024/saltillo",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 5,
                spaces: true,
                titulo: "Mazatlán",
                clave: "mazatlan",
                url: "/sin/2024/mazatlan",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Quéretaro",
                clave: "reynosa",
                url: "/sin/2024/queretaro",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 7,
                spaces: true,
                titulo: "Cuernavaca",
                clave: "cuernavaca",
                url: "/sin/2024/cuernavaca",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 2,
                spaces: true,
                titulo: "Puebla",
                clave: "puebla",
                url: "/sin/2024/puebla",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 3,
                spaces: true,
                titulo: "Veracruz",
                clave: "  veracruz",
                url: "/sin/2024/veracruz",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 4,
                spaces: true,
                titulo: "Coatzacoalcos",
                clave: "coatzacoalcos",
                url: "/sin/2024/coatzacoalcos",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 5,
                spaces: true,
                titulo: "Estado de México",
                clave: "edomex",
                url: "/sin/2024/edomex",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Villahermosa",
                clave: "villahermosa",
                url: "/sin/2024/villahermosa",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Oaxaca",
                clave: "oaxaca",
                url: "/sin/2024/oaxaca",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
            ],
          },
        ],
      },
      {
        id: 3,
        spaces: false,
        titulo: "Cotizador",
        clave: "cotizador",
        url: "/",
        win: "",
        icono: <MdOutlineRequestQuote />,
        submenu: true,
        submenus: [{
          id: 2,
          spaces: false,
          titulo: "Cotizador",
          clave: "cotizador",
          url: "/cot/cotizador",
          win: "",
          icono: <MdOutlineRequestQuote />,
          submenu: false,
          submenus: [],
        },],
      },

      {
        id: 5,
        spaces: true,
        titulo: "Estadísticas",
        clave: "estadisticas",
        url: "/",
        win: "",
        icono: <FaChartPie />,
        submenu: true,
        submenus: [
          {
            id: 5,
            spaces: true,
            titulo: "Control carretero",
            clave: "estcontrolcarretero",
            url: "/",
            win: "",
            icono: <FaChartPie />,
            submenu: true,
            submenus: [{
              id: 5,
              spaces: true,
              titulo: "Seguimiento contratistas",
              clave: "segcontatistas",
              url: "/est/segcontatistas",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            },],
          },
          {
            id: 5,
            spaces: true,
            titulo: "Control de facturas",
            clave: "estcontfacturas",
            url: "/",
            win: "",
            icono: <FaChartPie />,
            submenu: true,
            submenus: [{
              id: 5,
              spaces: true,
              titulo: "Seguimiento contratistas",
              clave: "segcontatistas",
              url: "/est/segcontatistas",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            }, {
              id: 5,
              spaces: true,
              titulo: "Control de siniestros especialesy servicios",
              clave: "siniestrosespeciales",
              url: "/est/siniestrosespeciales",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            }, {
              id: 5,
              spaces: true,
              titulo: "Seguimiento solicitudes de pago de equipo electronico y obra civil",
              clave: "pagoequipos",
              url: "/est/pagoequipos",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            }, {
              id: 5,
              spaces: true,
              titulo: "Reporte de montos reclmados CAPUFE",
              clave: "reclamadoscapufe",
              url: "/est/reclamadoscapufe",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            },],
          },
          {
            id: 5,
            spaces: true,
            titulo: "Siniestralidad",
            clave: "estsinestralidad",
            url: "/",
            win: "",
            icono: <FaChartPie />,
            submenu: true,
            submenus: [{
              id: 5,
              spaces: true,
              titulo: "Supervisión de sesiones quincenales",
              clave: "sesionesquincenales",
              url: "/est/sesionesquincenales",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            }, {
              id: 5,
              spaces: true,
              titulo: "Torre de control",
              clave: "torrecontrol",
              url: "/est/torrecontrol",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            }, {
              id: 5,
              spaces: true,
              titulo: "Analisis de siniestralidad",
              clave: "analisis",
              url: "/est/analisis",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            },],
          },
        ],
      },
      {
        id: 6,
        spaces: true,
        titulo: "Control carretero",
        clave: "controlcarretero",
        url: "/",
        win: "",
        icono: <FaRoute />,
        submenu: true,
        submenus: [{
          id: 6,
          spaces: true,
          titulo: "Control carretero",
          clave: "crlcarretero",
          url: "https://controlcarretero.com/gnp/login.aspx?ReturnUrl=%2fgnp%2fApplication%2fdashboard.aspx",
          win: "_blank",
          icono: <FaRoute />,
          submenu: false,
          submenus: [],
        },],
      },
/*capacitacion
      {
        id: 98,
        spaces: true,
        titulo: "Capacitación",
        clave: "Capacitación",
        url: "/",
        win: "",
        icono: <PiStudent />,
        submenu: true,
        submenus: [{
          id: 98,
          spaces: true,
          titulo: "Capacitación",
          clave: "Capacitación",
          url: "/cap/Capacitación",
          win: "",
          icono: <PiStudent />,
          submenu: false,
          submenus: [],
        },],
      },*/
      {
        id: 99,
        spaces: true,
        titulo: "Whatsapp",
        clave: "whatsap",
        url: "https://api.whatsapp.com/send?phone=5215578786789&amp;text=%C2%A1Hola%2C%20bienvenido%20al%20servicio%20de%20SINESTRY!%20%0AA%20trav%C3%A9s%20de%20este%20canal%2C%20podremos%20resolverte%20cualquier%20duda%20que%20tengas%20para%20la%20atenci%C3%B3n%20de%20siniestros.%0AEstamos%20aqu%C3%AD%20para%20ayudarte.%20%20%0A%C2%A1Gracias!",
        win: "_blank",
        icono: <IoLogoWhatsapp />,
        submenu: false,
        submenus: [],
      },
    ],
  },
  directorgnp: {
    menus: [
      {
        id: 1,
        spaces: false,
        titulo: "Siniestralidad",
        clave: "siniestralidad",
        url: "/",
        win: "",
        icono: <PiNotepadBold />,
        submenu: true,
        submenus: [
          {
            id: 1,
            spaces: true,
            titulo: "2023",
            clave: "sin2023",
            url: "/",
            win: "",
            icono: <PiNotepadBold />,
            submenu: true,
            submenus: [
              {
                id: 1,
                spaces: true,
                titulo: "Tijuana",
                clave: "tijuana",
                url: "/sin/2023/tijuana",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 2,
                spaces: true,
                titulo: "Hermosillo",
                clave: "hermosillo",
                url: "/sin/2023/hermosillo",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 3,
                spaces: true,
                titulo: "Reynosa",
                clave: "reynosa",
                url: "/sin/2023/reynosa",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 4,
                spaces: true,
                titulo: "Saltillo",
                clave: "saltillo",
                url: "/sin/2023/saltillo",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 5,
                spaces: true,
                titulo: "Mazatlán",
                clave: "mazatlan",
                url: "/sin/2023/mazatlan",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Quéretaro",
                clave: "reynosa",
                url: "/sin/2023/queretaro",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 7,
                spaces: true,
                titulo: "Cuernavaca",
                clave: "cuernavaca",
                url: "/sin/2023/cuernavaca",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 2,
                spaces: true,
                titulo: "Puebla",
                clave: "puebla",
                url: "/sin/2023/puebla",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 3,
                spaces: true,
                titulo: "Veracruz",
                clave: "veracruz",
                url: "/sin/2023/veracruz",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 4,
                spaces: true,
                titulo: "Coatzacoalcos",
                clave: "coatzacoalcos",
                url: "/sin/2023/coatzacoalcos",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 5,
                spaces: true,
                titulo: "Estado de México",
                clave: "edomex",
                url: "/sin/2023/edomex",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Villahermosa",
                clave: "villahermosa",
                url: "/sin/2023/villahermosa",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Oaxaca",
                clave: "oaxaca",
                url: "/sin/2023/oaxaca",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
            ],
          },
          {
            id: 1,
            spaces: true,
            titulo: "2024",
            clave: "sin2024",
            url: "/",
            win: "",
            icono: <PiNotepadBold />,
            submenu: true,
            submenus: [
              {
                id: 1,
                spaces: true,
                titulo: "Tijuana",
                clave: "tijuana",
                url: "/sin/2024/tijuana",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 2,
                spaces: true,
                titulo: "Hermosillo",
                clave: "hermosillo",
                url: "/sin/2024/hermosillo",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 3,
                spaces: true,
                titulo: "Reynosa",
                clave: "reynosa",
                url: "/sin/2024/reynosa",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 4,
                spaces: true,
                titulo: "Saltillo",
                clave: "saltillo",
                url: "/sin/2024/saltillo",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 5,
                spaces: true,
                titulo: "Mazatlán",
                clave: "mazatlan",
                url: "/sin/2024/mazatlan",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Quéretaro",
                clave: "reynosa",
                url: "/sin/2024/queretaro",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 7,
                spaces: true,
                titulo: "Cuernavaca",
                clave: "cuernavaca",
                url: "/sin/2024/cuernavaca",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 2,
                spaces: true,
                titulo: "Puebla",
                clave: "puebla",
                url: "/sin/2024/puebla",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 3,
                spaces: true,
                titulo: "Veracruz",
                clave: "  veracruz",
                url: "/sin/2024/veracruz",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 4,
                spaces: true,
                titulo: "Coatzacoalcos",
                clave: "coatzacoalcos",
                url: "/sin/2024/coatzacoalcos",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 5,
                spaces: true,
                titulo: "Estado de México",
                clave: "edomex",
                url: "/sin/2024/edomex",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Villahermosa",
                clave: "villahermosa",
                url: "/sin/2024/villahermosa",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
              {
                id: 6,
                spaces: true,
                titulo: "Oaxaca",
                clave: "oaxaca",
                url: "/sin/2024/oaxaca",
                win: "",
                icono: <PiNotepadBold />,
                submenu: false,
                submenus: [],
              },
            ],
          },
        ],
      },
      {
        id: 3,
        spaces: false,
        titulo: "Cotizador",
        clave: "cotizador",
        url: "/",
        win: "",
        icono: <MdOutlineRequestQuote />,
        submenu: true,
        submenus: [{
          id: 2,
          spaces: false,
          titulo: "Cotizador",
          clave: "cotizador",
          url: "/cot/cotizador",
          win: "",
          icono: <MdOutlineRequestQuote />,
          submenu: false,
          submenus: [],
        },],
      },

      {
        id: 5,
        spaces: true,
        titulo: "Estadísticas",
        clave: "estadisticas",
        url: "/",
        win: "",
        icono: <FaChartPie />,
        submenu: true,
        submenus: [
          {
            id: 5,
            spaces: true,
            titulo: "Control carretero",
            clave: "estcontrolcarretero",
            url: "/",
            win: "",
            icono: <FaChartPie />,
            submenu: true,
            submenus: [{
              id: 5,
              spaces: true,
              titulo: "Seguimiento contratistas",
              clave: "segcontatistas",
              url: "/est/segcontatistas",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            },],
          },
          {
            id: 5,
            spaces: true,
            titulo: "Control de facturas",
            clave: "estcontfacturas",
            url: "/",
            win: "",
            icono: <FaChartPie />,
            submenu: true,
            submenus: [{
              id: 5,
              spaces: true,
              titulo: "Seguimiento contratistas",
              clave: "segcontatistas",
              url: "/est/segcontatistas",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            }, {
              id: 5,
              spaces: true,
              titulo: "Control de siniestros especialesy servicios",
              clave: "siniestrosespeciales",
              url: "/est/siniestrosespeciales",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            }, {
              id: 5,
              spaces: true,
              titulo: "Seguimiento solicitudes de pago de equipo electronico y obra civil",
              clave: "pagoequipos",
              url: "/est/pagoequipos",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            }, {
              id: 5,
              spaces: true,
              titulo: "Reporte de montos reclmados CAPUFE",
              clave: "reclamadoscapufe",
              url: "/est/reclamadoscapufe",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            },],
          },
          {
            id: 5,
            spaces: true,
            titulo: "Siniestralidad",
            clave: "estsinestralidad",
            url: "/",
            win: "",
            icono: <FaChartPie />,
            submenu: true,
            submenus: [{
              id: 5,
              spaces: true,
              titulo: "Supervisión de sesiones quincenales",
              clave: "sesionesquincenales",
              url: "/est/sesionesquincenales",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            }, {
              id: 5,
              spaces: true,
              titulo: "Torre de control",
              clave: "torrecontrol",
              url: "/est/torrecontrol",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            }, {
              id: 5,
              spaces: true,
              titulo: "Analisis de siniestralidad",
              clave: "analisis",
              url: "/est/analisis",
              win: "",
              icono: <FaChartPie />,
              submenu: false,
              submenus: [],
            },],
          },
        ],
      },
      {
        id: 6,
        spaces: true,
        titulo: "Control carretero",
        clave: "controlcarretero",
        url: "/",
        win: "",
        icono: <FaRoute />,
        submenu: true,
        submenus: [{
          id: 6,
          spaces: true,
          titulo: "Control carretero",
          clave: "crlcarretero",
          url: "https://controlcarretero.com/gnp/login.aspx?ReturnUrl=%2fgnp%2fApplication%2fdashboard.aspx",
          win: "_blank",
          icono: <FaRoute />,
          submenu: false,
          submenus: [],
        },],
      },

/* CAPACITACION */
/*
{
  id: 98,
  spaces: true,
  titulo: "Capacitación",
  clave: "Capacitación",
  url: "/cap/capacitacion",
  win: "",
  icono: <PiStudent />,
  submenu: false,
  submenus: [],
},*/
      {
        id: 99,
        spaces: true,
        titulo: "Whatsapp",
        clave: "whatsap",
        url: "https://api.whatsapp.com/send?phone=5215578786789&amp;text=%C2%A1Hola%2C%20bienvenido%20al%20servicio%20de%20SINESTRY!%20%0AA%20trav%C3%A9s%20de%20este%20canal%2C%20podremos%20resolverte%20cualquier%20duda%20que%20tengas%20para%20la%20atenci%C3%B3n%20de%20siniestros.%0AEstamos%20aqu%C3%AD%20para%20ayudarte.%20%20%0A%C2%A1Gracias!",
        win: "_blank",
        icono: <IoLogoWhatsapp />,
        submenu: false,
        submenus: [],
      },
    ],
  },
};
export default rolesPermisos
