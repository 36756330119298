import { useState, useEffect } from "react";
import { Link } from 'react-router-dom'


import {
    RiUser2Fill,
    RiPrinterCloudFill,
    RiFileExcel2Fill,
} from "react-icons/ri";
import {
    RiEraserFill,
    RiPencilFill
} from "react-icons/ri";
const initialForm = {
    id: '',
    proyecto: 'gnpcapufe',
    nombre: false,
    rol: '',
    email: '',
    win: '',
    icono: '',
    submenu: false
}

const Permisosform = () => {
    return (
        <>
            <div className="w-full flex flex-col justify-center bg-white items-center">
                <div className="w-full bg-pink-200 h-[5vh] text-black">
                </div>
                <div className="flex- flex-col justify-between relative
                 overflow-x-auto shadow-md w-full h-[95vh] bg-white">
                    <div>
                        <span className="text-2xl text-gray-600 pl-5">Permisos</span>
                    </div>
                    <div className="w-full flex flex-cols gap-3 justify-end flex py-2 pr-2">
                        <button className="flex items-center gap-2 h-[35px] bg-orange-400 text-base text-white p-2 rounded-[5px]">
                            <RiUser2Fill />
                        </button>
                     {/*   <button className=" flex items-center gap-2 h-[35px] bg-green-800 text-base text-white p-2 rounded-[5px]">
                            <RiFileExcel2Fill />
                        </button>
                        <button className=" flex items-center gap-2 h-[35px] bg-blue-800 text-base text-white p-2 rounded-[5px]">
                            <RiPrinterCloudFill />
                        </button>*/}
                    </div>
                    <table className="w-full">
                        <thead> 
                            <tr className="bg-titTablas text-txtTablas">
                                <th></th>
                                <th>Modulo</th>                              
                                <th>Permiso</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="flex w-[3vw]">
                                  <Link><RiPencilFill /></Link>  
                                  <Link></Link>                                 
                                </td>
                                <td>Siniestralidad</td>                           
                                <td>Si</td>
                            </tr>
                         
                        </tbody>

                    </table>
                </div>
            </div>


        </>
    )
}
export default Permisosform