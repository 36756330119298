import { signInWithGoogle } from "../../data/fire";
import logotipo from "./logo_gnpsinestry.svg";
import fondo from "./layersinestry.png";
const Conteneidologin = () => {
  return (
    <main className="flex-grow bg-gray-100">
      <div
        className="flex flex-col justify-center items-center gap-2 
        sm:flex-col  
        md:flex-row   
        lg:flex-row 
        xl:flex-row"
      >
        <div
          className="
          justify-center min-w-[70vw]
        sm:min-w-[70vw] sm:px-[8vw] rounded-[10px]
        md:min-w-[60vw] md:min-h-[17vh] md:px-[8vw]
        xl:min-w-[62vw] xl:min-h-[17vh] xl:px-[8vw] xl:pt-[4vw]
        "
        >
          <img className="w-[100%] rounded-[10px]" src={fondo} alt="" />
        </div>
        <div
          className="
          justify-between bg-white gap-1 flex flex-col 
          min-w-[60vw] min-h-[42vh] px-10 pb-5 mx-[12vh] rounded-[10px]
          bg-white border-1 border-gray-200 shadow-md shadow-gray-400
          sm:min-w-[60vw] sm:min-h-[42vh] sm:mt-[12vh] 
          md:min-w-[35vw] md:min-h-[40vh] md:mt-[16vh] md:mt-[10vh]
          xl:min-w-[25vw] xl:min-h-[62vh] xl:mt-[13vh] xl:mt-[10vh]
         "
        >
          <div className="w-[100%]">
            <img className="p-5" src={logotipo} alt="" />
          </div>

          <div className="w-full flex flex-col p-3">
            <p
              className="px-2 text-center text-titulo1LoginSinApp text-[1.5rem] 
              font-bold
            sm:px-4
            xl:px-4"
            >
              Bienvenido a SiniestrosApp
            </p>
          </div>
          <div className="w-full flex flex-col">
            <p
              className=" text-center text-titulo2LoginSinApp text-bold px-10 pb-2
          sm:px-10
          xl:px-10"
            >
              Usa tu cuenta de Google para iniciar sesión
            </p>
          </div>
          <div className="w-[100%] flex flex-col shadow-sm rounded-[10px] items-center shadow-gray-600">
            <button
              onClick={signInWithGoogle}
              className="flex border-1 px-1 rounded-xl py-1  bg-white items-center 
                justify-center gap-1 active:scale-[1] active:duration-75 transition-all 
                hover:scale-[1.02] ease-in-out"
            >
              <svg
                width="32"
                height="32"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.26644 9.76453C6.19903 6.93863 8.85469 4.90909 12.0002 4.90909C13.6912 4.90909 15.2184 5.50909 16.4184 6.49091L19.9093 3C17.7821 1.14545 15.0548 0 12.0002 0C7.27031 0 3.19799 2.6983 1.24023 6.65002L5.26644 9.76453Z"
                  fill="#EA4335"
                />
                <path
                  d="M16.0406 18.0142C14.9508 18.718 13.5659 19.0926 11.9998 19.0926C8.86633 19.0926 6.21896 17.0785 5.27682 14.2695L1.2373 17.3366C3.19263 21.2953 7.26484 24.0017 11.9998 24.0017C14.9327 24.0017 17.7352 22.959 19.834 21.0012L16.0406 18.0142Z"
                  fill="#34A853"
                />
                <path
                  d="M19.8342 20.9978C22.0292 18.9503 23.4545 15.9019 23.4545 11.9982C23.4545 11.2891 23.3455 10.5255 23.1818 9.81641H12V14.4528H18.4364C18.1188 16.0119 17.2663 17.2194 16.0407 18.0108L19.8342 20.9978Z"
                  fill="#4A90E2"
                />
                <path
                  d="M5.27698 14.2663C5.03833 13.5547 4.90909 12.7922 4.90909 11.9984C4.90909 11.2167 5.03444 10.4652 5.2662 9.76294L1.23999 6.64844C0.436587 8.25884 0 10.0738 0 11.9984C0 13.918 0.444781 15.7286 1.23746 17.3334L5.27698 14.2663Z"
                  fill="#FBBC05"
                />
              </svg>
              <span className="text-[12px] "> Iniciar con Google</span>
            </button>
          </div>
        </div>
      </div>
    </main>
  );
};
export default Conteneidologin;
