// VideoPlayer.js
import React, { useRef, useState } from 'react';
import { Link } from "react-router-dom";
const VideoPlayer = ({ videoPath,titulo }) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayPause = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleFullScreen = () => {
    if (videoRef.current.requestFullscreen) {
      videoRef.current.requestFullscreen();
    } else if (videoRef.current.webkitRequestFullscreen) { // Para Safari
      videoRef.current.webkitRequestFullscreen();
    } else if (videoRef.current.msRequestFullscreen) { // Para IE/Edge
      videoRef.current.msRequestFullscreen();
    }
  };

  return (
<>
    <div className="flex gap-2 justify-center items-center shadow-gray-400 shadow ">
      <video
        ref={videoRef}
        src={videoPath}  // Usar la URL del video
        controls
        controlsList="nodownload noplaybackrate"
        onContextMenu={(e) => e.preventDefault()}
        style={{ width: '30%', height: 'auto' }}
      ></video>     
    </div><Link className="w-full text-[.6em]" onClick={handleFullScreen}>{titulo}</Link></>
  );
};


export default VideoPlayer;
