import { useState, useEffect } from "react";
import { Link } from 'react-router-dom'
import {
    RiUser2Fill,
    RiPrinterCloudFill,
    RiFileExcel2Fill,
} from "react-icons/ri";
import {
    RiEraserFill,
    RiPencilFill
} from "react-icons/ri";
const initialForm = {
          txtid: '',
    txtproyecto: '',
         txtrol: '',
     txtestatus: false
}
const validationForm=()=>{
    let errores={};
   // if(!Form.)
}

const Rolesform = () => {
    return (
        <>
            <div className="w-full flex flex-col justify-center bg-white items-center">
                <div className="w-full bg-pink-200 h-[5vh] text-black">
                </div>
                <div className="flex- flex-col justify-between relative
                 overflow-x-auto shadow-md w-full h-[95vh] bg-white">
                    <div>
                        <span className="text-2xl text-gray-600 pl-5">Roles</span>
                    </div>
                    <div className="w-full flex flex-cols gap-3 justify-end flex py-2 pr-2">
                        <button className="flex items-center gap-2 h-[35px] bg-orange-400 text-base text-white p-2 rounded-[5px]">
                            <RiUser2Fill />
                        </button>
                        {/*   <button className=" flex items-center gap-2 h-[35px] bg-green-800 text-base text-white p-2 rounded-[5px]">
                            <RiFileExcel2Fill />
                        </button>
                        <button className=" flex items-center gap-2 h-[35px] bg-blue-800 text-base text-white p-2 rounded-[5px]">
                            <RiPrinterCloudFill />
                        </button>*/}
                    </div>
                    {/* formualrio d */}
                    <div className="bg-gray-100 w-100 h-45 grid grid-cols-4 gap-2 p-4">
                       
                            <label className="grid-col-2 relative">
                                <input
                                    type="text"
                                    name="txtrol"
                                    className="bg-white ring-2 ring-gray-400 w-full h-6 rounded peer px-1
                        transition-all outline-none focus:ring-gray-400 valid:ring-gray-400"
                                />
                                <span className="absolute top-1/2 -translate-y-1/2 left-3 perr-focus:top-0
                                peer-focus:text-xs peer-focus:font-semibold transition-all bg-white px-2
                                cursor-txt peer-valid:top-0 peer-valid:text-xs peer-valid:font-semibold
                                text-gray-500 flex items-center gap-2">Rol <span clasName="text-red-500">*</span></span>
                            </label>
                            <div className="flex justify-beetween grid-col-1 h-[4vh] bg-red-300 
                                      hover:bg-blue-300 text-white text-center rounded ">
                                <span className="w-full text-center">Guardar</span>

                            </div>
                            {/*   <label className="grid-col-2 relative">
                                <select className="w-[20w] ring-2 ring-gray-400 w-full h-6 rounded peer px-1 mr-1 text-[0.8rem]
                                transition-all outline-none focus-ring-gray-400 valid:ring-gray-400">
                                    <option>---</option>
                                </select>
                                <span className="absolute top-1/2 -translate-y-1/2 left-3 perr-focus:top-0
                                peer-focus:text-xs peer-focus:font-semibold transition-all bg-white px-2
                                cursor-txt peer-valid:top-0 peer-valid:text-xs peer-valid:font-semibold
                                text-gray-500 flex items-center gap-2">Rol <span clasName="text-red-500">*</span></span>
                            </label>*/}
                        
                    </div>
                    <div className="px-4">
                        <table className="w-full">
                            <thead>
                                <tr className="bg-titTablas text-txtTablas">
                                    <th></th>
                                    <th>Nomadsasdbre</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="flex w-[3vw]">
                                        <Link><RiPencilFill /></Link>
                                    </td>
                                    <td>ADMIN</td>
                                </tr>
                                <tr>
                                    <td className="flex w-[3vw]">
                                        <Link><RiPencilFill /></Link>
                                    </td>
                                    <td>DIRECTOR GNP</td>
                                </tr>
                            </tbody>

                        </table>
                    </div>

                </div>
            </div>


        </>
    )
}
export default Rolesform