import { Link } from "react-router-dom";
import logosinestry from "../../../auth/Login/logo_gnpsinestry.svg";
import { React, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { PiNotepadBold } from "react-icons/pi";
import { IoLogoWhatsapp } from "react-icons/io";
import rolesPermisos from '../../../data/rolesPermisos'
import { FaChartPie } from "react-icons/fa";
import { GoDeviceCameraVideo } from "react-icons/go";
import VideoPlayer from "../../../components/Admin/VideoPlayer";
const Capacitacion = ({ nombre, mdstatus, eseditable }) => {
    const urlvideo='https://gnp-capufe.siniestrosapp.mx/asset/videos/';
    return (
        <div className="container  justify-center">
            <div
                className="absolute top-[3vh] w-[70%] rounded-[2.5vw] shadow-gray-400 shadow -mt-[1vh] ml-[3.5vw] 
                       flex flex-col justify-center"
            >
                <div className="px-[22vw] pt-1">
                    <img  src={logosinestry} alt="" />
                </div>
                <div className="w-full flex flex- justify-center">
                    <span className="text-[1.5rem] justify-center items-center font-bold text-titulo1LoginSinApp ">
                        Capacitación
                    </span>
                </div>
                
                <div className="w-full flex justify-left  ">
                    <div className="w-full gap-2 px-10">
                        <p className="py-1 text-justify text-[.8rem]">
                            Aquí encontrarás videos de capacitación,con la finalidad de manter un alto estandar de calidad y servicio profesional.
                        </p> 
                        <p className="py-1 text-justify"></p>
                    </div>   
                </div>
                <ul className="w-[100%] px-5 items-left justify-start gap-3 grid grid-cols-3 py-[2vh] text-[1.2rem]">
                <li className="w-[100%]">
                <VideoPlayer videoPath="./videos/video1.mp4" titulo="1. Daños a dispositivos de seguridad"/>
               
                </li>
                <li className="w-[100%]">
                <VideoPlayer videoPath="./videos/video2.mp4" titulo="2. Daños a la carpeta asfáltica, Zonas laterales y obras de drenaje"/>   
               
                </li>
                <li className="w-[100%]">
                <VideoPlayer videoPath="./videos/video3.mp4" />   
                <span className={` text-[.7rem] pt-2 font-semibold flex-1 `}
                        >
                             3. Daños a señalamiento vertical y horizontal
                             </span>

                </li>
                    
                <li className="w-[100%]">
                <VideoPlayer videoPath="./videos/video4.mp4"  titulo="4. Recomendaciones clave para atencíon de siniestros" />   
               </li>
                <li className="w-[100%] flex flex-col justify-center">
                <VideoPlayer videoPath="./videos/video5.mp4" titulo="5. Importancia de evidenciafotográfica y documentacíón"/>   
                

                </li>
                 
                </ul>

            </div>

        </div>)
}
export default Capacitacion;